import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import ResponsiveDialog, { ResponsiveDialogProps } from "@/features/ResponsiveDialog/ResponsiveDialog";
import { themeColors } from "@/utils/themeColors";
import br from "@/styles/breakpoints.module.css";
import { clsx } from "clsx";

export const SidebarPopupResponsiveDialog = ({
    DialogStyle,
    SlideMenuStyle,
    children,
    ...dialogProps
}: ResponsiveDialogProps) => {
    return (
        <ResponsiveDialog
            DialogStyle={{
                borderRadius: "2rem",
                width: "85rem",
                height: "50rem",
                flexDirection: "row",
                overflow: "hidden",
                ...DialogStyle,
            }}
            SlideMenuStyle={{ padding: "3rem 2rem", overflow: "hidden", ...SlideMenuStyle }}
            {...dialogProps}>
            {children}
        </ResponsiveDialog>
    );
};

/** The right part main content of the popup */
export const SidebarPopupMainContainer = ({ children, style, ...divProps }: React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <FlexColumn
            className={clsx(br.smDownPadding0, br.smDownHeight100)}
            style={{ flex: 1, padding: "3rem 2rem 0", ...style }}
            {...divProps}>
            {children}
        </FlexColumn>
    );
};

/** The left part tabs of the popup */
export const SidebarPopupSideContainer = ({ children, style, ...divProps }: React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <FlexColumn
            className={clsx("scrollbar-thin", br.smDownPadding0, br.smDownRow, br.smDownWidth100)}
            style={{
                overflow: "auto",
                backgroundColor: themeColors.neutralWhite,
                width: "21rem",
                borderRight: `1px solid ${themeColors.neutral1}`,
                padding: "3rem 1rem 0 2rem",
                marginBottom: "1.5rem",
                gap: "0.7rem",
                flexShrink: 0,
                ...style,
            }}
            {...divProps}>
            {children}
        </FlexColumn>
    );
};

export const SidebarPopupBottomContainer = ({ children, style, ...divProps }: React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <FlexRowAlignCenter
            {...divProps}
            style={{
                padding: "2rem 2rem",
                height: "7.7rem",
                margin: "auto -2rem 0",
                gap: "1.5rem",
                justifyContent: "flex-end",
                borderTop: `2px solid ${themeColors.neutral1}`,
                ...style,
            }}>
            {children}
        </FlexRowAlignCenter>
    );
};
