import { ASSETS_URL } from "@/config/deployConstants";
import { themeColors } from "@/utils/themeColors";
import { AI_LANDING_TOOLS, AI_TOOLS } from "@knowt/syncing/constants/ai-tools";
import { createToolIcon } from "./utils";
import { useRouter } from "@bprogress/next";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { FlexColumnAlignJustifyCenter } from "@/components/Flex";
import Image from "next/image";
import { AICompletionType, ClassPermission } from "@knowt/syncing/graphql/schema";
import { useClass } from "@knowt/syncing/hooks/classes/useClass";
import { useParams } from "next/navigation";
import { useFolder } from "@knowt/syncing/hooks/folders/useFolders";
import { iconSizes } from "@/utils/iconProps";
import SparkleIcon from "@/components/icons/SparkleIcon";

export type CREATE_TOOL_ITEM = {
    id?: string;
    title: string;
    description: string;
    href?: string;
    onClick?: () => Promise<void> | void;
    color?: string;
    ToolIcon?: React.ReactElement;
    // we will filter the tools to not show `disabled` tools
    disabled?: boolean;
    tool?: AICompletionType;
    sx?: React.CSSProperties;
};

export enum ToolType {
    AI_TOOLS = "AI Tools",
    FLASHCARDS = "Flashcards",
    NOTES = "notes",
    CHAT_ACTIVITY = "Chat Activity",
    FOLDER = "Folder",
    CREATE_JOIN_CLASS = "Create/Join Class",
}

export type TOOL_BY_GROUP = {
    type: ToolType;
    title: string;
    tools: CREATE_TOOL_ITEM[];
    img: string;
    background: string;
    icon?: React.ReactNode;
    // we will filter the tools to not show `disabled` tools
    disabled?: boolean;
};

/**
 * Hook to create the tools constants. we need this to be hook because some action can only be called by calling other hooks (useCurrentUser & useRouter).
 */
export const useToolByGroup = ({
    setIsCreatingFolder,
    setIsImportingFromQuizlet,
    setIsImportingManually,
    setIsMovingFileToClass,
    setIsCreatingClass,
}: {
    setIsCreatingFolder: (isCreatingFolder: boolean) => void;
    setIsImportingFromQuizlet: (isImportingFromQuizlet: boolean) => void;
    setIsImportingManually: (isImportingManually: boolean) => void;
    setIsMovingFileToClass: (isMovingFileToClass: boolean) => void;
    setIsCreatingClass: (isCreatingClass: boolean) => void;
}): Array<TOOL_BY_GROUP> => {
    const router = useRouter();

    const params = useParams<{ folderId: string; classId: string }>();
    const { userId, user, isTeacher } = useCurrentUser();

    const { folder: _folder } = useFolder({ folderId: params?.folderId, isEnabled: !!params?.folderId });
    const { course: _course, permission } = useClass({
        classId: params?.classId || _folder?.classId,
        isEnabled: !!params?.classId || !!_folder?.classId,
    });

    const folder = _folder?.userId === userId || permission === ClassPermission.ALL ? _folder : null;
    const course = permission === ClassPermission.ALL ? _course : null;

    return [
        {
            type: ToolType.AI_TOOLS,
            title: "AI Tools",
            tools: [...Object.values(AI_LANDING_TOOLS), ...Object.values(AI_TOOLS)].map(tool => ({
                ...tool,
                ToolIcon: (
                    <FlexColumnAlignJustifyCenter
                        style={{
                            backgroundColor: tool.color,
                            width: "3.5rem",
                            height: "3.5rem",
                            borderRadius: "0.8rem",
                        }}>
                        <Image src={`/images/aiTools/${tool.tool}.svg`} alt={tool.title} width={20} height={20} />
                    </FlexColumnAlignJustifyCenter>
                ),
            })),
            img: `${ASSETS_URL}/images/ai-summarizer-purple-sparkle.svg`,
            icon: <SparkleIcon size={iconSizes.SM_S} />,
            background: themeColors.aiGradientFlip,
            disabled: !isTeacher,
        },
        {
            type: ToolType.FLASHCARDS,
            title: "Flashcards",
            tools: [
                {
                    title: "Create from scratch",
                    description: "Start writing your own flashcards for students",
                    ToolIcon: createToolIcon({
                        background: themeColors.flashcardsLight,
                        img: `${ASSETS_URL}/images/flashcard-icon.svg`,
                    }),
                    onClick: async () => {
                        const { makeStandaloneFlashcardSet } = await import("@/hooks/flashcards/utils");
                        const { flashcardSetId } = await makeStandaloneFlashcardSet({
                            userId,
                            ...(folder
                                ? { folderId: folder.folderId, public: folder?.public, password: folder?.password }
                                : {}),
                            ...(course?.classId ? { classId: course?.classId } : {}),
                        });
                        router.push(`/flashcards/${flashcardSetId}/edit`);
                    },
                },
                {
                    title: "Create from a  PDF, PPT, or Video/Audio",
                    description: "We’ll create flashcards from your file",
                    ToolIcon: createToolIcon({
                        background: themeColors.aiGradient,
                        img: `${ASSETS_URL}/images/ai-summarizer-purple-sparkle.svg`,
                        icon: <SparkleIcon size={iconSizes.MD_S} />,
                    }),
                    href: "/ai-pdf-summarizer",
                },
                {
                    title: "Create from live recording",
                    description: "Start a live lecture recording now.",
                    ToolIcon: createToolIcon({
                        background: themeColors.subject3,
                        img: `${ASSETS_URL}/images/live-recording.png`,
                    }),
                    href: "/ai-lecture-note-taker",
                },
                {
                    title: "Import from Quizlet",
                    description: "Bring over a flashcard set from Quizlet in 5 seconds.",
                    ToolIcon: createToolIcon({
                        background: themeColors.docsLight,
                        img: `${ASSETS_URL}/images/quizlet-icon.svg`,
                    }),
                    onClick: () => {
                        setIsImportingFromQuizlet(true);
                        setIsImportingManually(false);
                    },
                },
                {
                    title: "Import manually",
                    description: "Import a flashcard set from a list of terms.",
                    ToolIcon: createToolIcon({
                        background: themeColors.docsLight,
                        img: `${ASSETS_URL}/images/quizlet-import-manually.svg`,
                    }),
                    onClick: () => {
                        setIsImportingFromQuizlet(true);
                        setIsImportingManually(true);
                    },
                },
                {
                    disabled: !isTeacher || !course,
                    title: "Move an existing file",
                    description: "Move an existing file to a new location.",
                    ToolIcon: createToolIcon({
                        background: themeColors.background,
                        img: `${ASSETS_URL}/images/move-files-icon.svg`,
                    }),
                    onClick: () => setIsMovingFileToClass(true),
                },
            ].filter(tool => !tool.disabled),
            img: `${ASSETS_URL}/images/flashcard-icon.svg`,
            background: themeColors.flashcardsLight,
        },
        {
            type: ToolType.NOTES,
            title: "Notes",
            tools: [
                {
                    id: "create-popup-tab-notes-create-from-scratch",
                    title: "Create from scratch",
                    description: "Start writing your own notes for students",
                    ToolIcon: createToolIcon({
                        background: themeColors.docsLight,
                        img: `${ASSETS_URL}/images/note-icon.svg`,
                    }),
                    onClick: async () => {
                        if (!userId) {
                            router.push("/ai-notes");
                            return;
                        }

                        const { createAndOpenNewNote } = await import("@/utils/navigation/notes");

                        const props: Record<string, string> = {};
                        if (folder) {
                            props.folderId = folder?.folderId;
                        }
                        if (course?.classId) {
                            props.classId = course?.classId;
                        }

                        await createAndOpenNewNote(props, user, router);
                    },
                },
                {
                    title: "Create from a  PDF, PPT, or Video/Audio",
                    description: "We’ll create notes from your file",
                    ToolIcon: createToolIcon({
                        background: themeColors.aiGradient,
                        img: `${ASSETS_URL}/images/ai-summarizer-purple-sparkle.svg`,
                        icon: <SparkleIcon size={iconSizes.SM} />,
                    }),
                    href: "/ai-pdf-summarizer",
                },
                {
                    title: "Create from live recording",
                    description: "Start a live lecture recording now.",
                    ToolIcon: createToolIcon({
                        background: themeColors.subject3,
                        img: `${ASSETS_URL}/images/live-recording.png`,
                    }),
                    href: "/ai-lecture-note-taker",
                },
                {
                    disabled: !isTeacher || !course,
                    title: "Move an existing file",
                    description: "Move an existing file to a new location.",
                    ToolIcon: (
                        <>
                            {createToolIcon({
                                background: themeColors.background,
                                img: `${ASSETS_URL}/images/move-files-icon.svg`,
                            })}
                        </>
                    ),
                    onClick: () => setIsMovingFileToClass(true),
                },
            ].filter(tool => !tool.disabled),
            img: `${ASSETS_URL}/images/note-icon.svg`,
            background: themeColors.docsLight,
        },
        {
            type: ToolType.CHAT_ACTIVITY,
            title: "Chat Activity",
            tools: [], // we're using custom component for this
            img: `${ASSETS_URL}/images/chat-activity-icon.png`,
            background: themeColors.mcStudyLight4,
            disabled: !isTeacher,
        },
        {
            type: ToolType.FOLDER,
            title: "Folder",
            tools: [
                {
                    title: "Create Folder",
                    description: "Organize your files in folders",
                    ToolIcon: (
                        <>
                            {createToolIcon({
                                background: themeColors.neutral1,
                                img: `${ASSETS_URL}/images/folder-icon.svg`,
                            })}
                        </>
                    ),
                    onClick: async () => setIsCreatingFolder(true),
                },
            ],
            img: `${ASSETS_URL}/images/folder-icon.svg`,
            background: themeColors.docsLight,
        },
        {
            type: ToolType.CREATE_JOIN_CLASS,
            title: isTeacher ? "Create/Join Class" : "Join Class",
            tools: [
                {
                    title: "Create Class",
                    description: "Share files and track student progress",
                    ToolIcon: (
                        <>
                            {createToolIcon({
                                background: themeColors.mcStudyLight4,
                                img: `${ASSETS_URL}/images/class-icon.svg`,
                            })}
                        </>
                    ),
                    onClick: async () => setIsCreatingClass(true),
                    disabled: !isTeacher,
                },
                {
                    title: "Join Class",
                    description: isTeacher
                        ? "Collorate with other teachers"
                        : "Join a class to learn with your classmates",
                    ToolIcon: createToolIcon({
                        background: themeColors.mcStudyLight4,
                        img: `${ASSETS_URL}/images/class-icon.svg`,
                    }),
                    onClick: async () => {
                        router.push("/class/join");
                    },
                },
            ].filter(t => !t.disabled),
            img: `${ASSETS_URL}/images/class-icon.svg`,
            background: themeColors.mcStudyLight4,
        },
    ].filter(t => !t.disabled);
};
