import { SxProps } from "@mui/material/styles";
import { themeColors } from "@/utils/themeColors";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { MouseEvent } from "react";

const ToggleSwitch = ({
    checked,
    onChange,
    sx,
    thumbStyle,
    disabled = false,
    checkedColor,
    uncheckedColor,
}: {
    checked: boolean;
    onChange: (newCheckValue: boolean, e: MouseEvent<HTMLButtonElement>) => void;
    sx?: SxProps & { height: string; width: string };
    thumbStyle?: React.CSSProperties;
    disabled?: boolean;
    checkedColor?: string;
    uncheckedColor?: string;
}) => {
    return (
        <CircularRectTextButton
            onClick={e => {
                if (!disabled) {
                    onChange(!checked, e);
                }
            }}
            sx={{
                position: "relative",
                width: "6rem",
                height: "2.8rem",
                backgroundColor: checked
                    ? checkedColor || themeColors.neutralBlack
                    : uncheckedColor || themeColors.neutral2,
                transition: "background-color 0.2s ease-in-out",
                boxShadow: null,
                ...sx,
            }}>
            <span
                style={{
                    position: "absolute",
                    top: "2.5%",
                    left: checked ? (sx?.width ? `calc(${sx.width})` : "3rem") : "0.2rem",
                    borderRadius: "100%",
                    width: "46%",
                    height: "95%",
                    backgroundColor: themeColors.neutralWhite,
                    transition: "left 0.2s ease-in-out",
                    ...thumbStyle,
                }}
            />
        </CircularRectTextButton>
    );
};

export default ToggleSwitch;
