"use client";

import { UpdateUserAlerts, UpdateUserAlertsMethod, generateUpdatedUserAlerts } from "@/hooks/guidedEvents/utils";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { platform } from "@/platform";
import { safeParse } from "@/utils/stringUtils";
import { createContext, useContextSelector } from "@/utils/use-context-selector";
import {
    AllEventNames,
    GuidedEventAction,
    GuidedEventObject,
    allEventNamesList,
} from "@knowt/syncing/hooks/guidedEvents/guidedEvents";
import { fetchAuthSession } from "aws-amplify/auth";
import { uniq } from "lodash-es";
import { uniqBy } from "lodash-es";
import { union } from "lodash-es";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { updateUserInfo } from "../user/utils";

export type UserAlertsType =
    | {
          done: string[];
          todo: GuidedEventObject[];
      }
    | undefined;

type UserAlertsContextValue = {
    userAlerts: UserAlertsType;
    addTodoEvent: (event: GuidedEventObject) => void;
    closeEvent: (eventName: string) => void;
    commitUpdateUserAlerts: () => Promise<void>;
    removeFromDone: (eventName: string) => void;
    decrementVisitCount: (event: GuidedEventObject) => void;
    removeFromTodo: (event: GuidedEventObject) => void;
    refreshUserAlerts: ({ events }: { events: GuidedEventObject[] }) => {
        eventsToStage: GuidedEventObject[];
        eventsToRemoveFromDone: string[];
        eventsToRemoveFromTodo: GuidedEventObject[];
        eventsToDecrementVisitCount: GuidedEventObject[];
    };
    allStagedEvents?: AllEventNames[];
    setAllStagedEvents?: Dispatch<SetStateAction<string[]>>;
};

const UserAlertsContext = createContext<UserAlertsContextValue>(null);

export const UserAlertsContextProvider = ({ children }) => {
    const { user } = useCurrentUser({ waitForFallbackData: true });

    const userAlerts = useMemo(() => {
        if (!user) return undefined;
        return (safeParse(user?.alerts) ?? {
            done: [],
            todo: [],
        }) as UserAlertsType;
    }, [user]);

    const isUpdateInProgress = useRef<boolean>(false);
    const pendingUpdates = useRef<UpdateUserAlerts[]>([]);

    const [allStagedEvents, setAllStagedEvents] = useState<string[]>([]);

    const cleanDuplicateEvents = useCallback((data: UserAlertsType) => {
        const { done, todo } = data;

        const cleanedDone = uniq(done);

        const cleanedTodo = uniqBy(todo, "eventName")?.filter((event: GuidedEventObject) => {
            const isEventDone = cleanedDone.includes(event.eventName);
            const isActionToAdd = !event.action;

            return !(isEventDone && isActionToAdd);
        });

        return {
            done: cleanedDone,
            todo: cleanedTodo,
        };
    }, []);

    const enqueueUpdateUserAlerts = useCallback(
        (updateAction: UpdateUserAlerts) => {
            pendingUpdates.current = union(pendingUpdates.current, [updateAction]);
        },
        [pendingUpdates]
    );

    const commitUpdateUserAlerts = useCallback(async () => {
        if (!pendingUpdates.current.length) return;

        let newData = { ...userAlerts };
        const dispatchedUpdates = [];

        for (const update of pendingUpdates.current) {
            newData = cleanDuplicateEvents(generateUpdatedUserAlerts({ userAlerts: newData, updatedFields: update }));
            dispatchedUpdates.push(update);
        }

        try {
            await updateUserInfo({ alerts: JSON.stringify(newData) });
        } catch (e) {
            const { report } = await platform.analytics.logging();
            report(e, "commitUpdateUserAlerts", { dispatchedUpdates, userId: user.ID, userAlerts });
            await fetchAuthSession({ forceRefresh: true });
            const toast = await platform.toast();
            toast.error(
                "Something went wrong, please reload the page! If this issue persists, please contact support."
            );
        } finally {
            // setPendingUpdates(pendingUpdates.filter(update => !dispatchedUpdates.includes(update)));
            pendingUpdates.current = pendingUpdates.current.filter(update => !dispatchedUpdates.includes(update));
        }
    }, [cleanDuplicateEvents, pendingUpdates, user, userAlerts]);

    useEffect(() => {
        if (!pendingUpdates.current.length || isUpdateInProgress.current) return;

        isUpdateInProgress.current = true;
        commitUpdateUserAlerts().finally(() => {
            isUpdateInProgress.current = false;
        });
    }, [commitUpdateUserAlerts, pendingUpdates, userAlerts]);

    const addTodoEvent = useCallback(
        (event: GuidedEventObject) => {
            if (!userAlerts) return;

            enqueueUpdateUserAlerts({
                method: UpdateUserAlertsMethod.ADD_TO_TODO,
                event,
            });
        },
        [enqueueUpdateUserAlerts, userAlerts]
    );

    const closeEvent = useCallback(
        (eventName: string) => {
            if (!userAlerts) return;

            enqueueUpdateUserAlerts({
                method: UpdateUserAlertsMethod.ADD_TO_DONE,
                eventName,
            });
        },
        [enqueueUpdateUserAlerts, userAlerts]
    );

    const removeFromDone = useCallback(
        (eventName: string) => {
            if (!userAlerts) return;

            enqueueUpdateUserAlerts({
                method: UpdateUserAlertsMethod.REMOVE_FROM_DONE,
                eventName,
            });
        },
        [enqueueUpdateUserAlerts, userAlerts]
    );

    const removeFromTodo = useCallback(
        (event: GuidedEventObject) => {
            if (!userAlerts) return;

            enqueueUpdateUserAlerts({
                method: UpdateUserAlertsMethod.REMOVE_FROM_TODO,
                event,
            });
        },
        [enqueueUpdateUserAlerts, userAlerts]
    );

    const decrementVisitCount = useCallback(
        (event: GuidedEventObject) => {
            if (!userAlerts) return;

            enqueueUpdateUserAlerts({
                method: UpdateUserAlertsMethod.DECREMENT_VISIT_COUNT,
                event,
            });
        },
        [enqueueUpdateUserAlerts, userAlerts]
    );

    const refreshUserAlerts = useCallback(
        ({ events }: { events: GuidedEventObject[] }) => {
            let eventsToStage: GuidedEventObject[] = [];
            let eventsToRemoveFromDone: string[] = [];
            let eventsToRemoveFromTodo: GuidedEventObject[] = [];
            let eventsToDecrementVisitCount: GuidedEventObject[] = [];

            const currentEventNamesList = events?.map(event => event.eventName);

            const refreshDoneList = (doneList: string[]) => {
                const possibleEventsToRemoveFromDone = doneList.filter(
                    (event: AllEventNames) => !allEventNamesList.includes(event)
                );

                return possibleEventsToRemoveFromDone.forEach((event: string) => {
                    eventsToRemoveFromDone = [...eventsToRemoveFromDone, event];
                });
            };

            const refreshTodoList = (todoList: GuidedEventObject[]) => {
                const todoListWithDeletedEventsRemoved = todoList.filter((event: GuidedEventObject) =>
                    allEventNamesList.includes(event.eventName)
                );

                const possibleEventsToRemoveFromTodo = todoList.filter(
                    (event: GuidedEventObject) => !allEventNamesList.includes(event.eventName)
                );

                possibleEventsToRemoveFromTodo.forEach(event => {
                    eventsToRemoveFromTodo = [...eventsToRemoveFromTodo, event];
                });

                todoListWithDeletedEventsRemoved.forEach((event: GuidedEventObject) => {
                    const rawEvent = events.find(e => e.eventName === event.eventName);

                    const shouldSkipForThisHookCall =
                        !currentEventNamesList.includes(event.eventName) ||
                        (rawEvent.platform !== undefined && rawEvent.platform !== platform.platformType);

                    if (shouldSkipForThisHookCall) return event;

                    if (event.visitCount - 1 > 0) {
                        eventsToDecrementVisitCount = [...eventsToDecrementVisitCount, event];
                        return;
                    }

                    if (event.action === GuidedEventAction.REMOVE) removeFromDone(event.eventName);

                    eventsToStage = [...eventsToStage, event];

                    return undefined;
                });
            };

            Object.values(userAlerts)?.map((alerts, index) => {
                if (index === 0) return refreshDoneList(alerts as string[]);

                return refreshTodoList(alerts as GuidedEventObject[]);
            });

            return {
                eventsToStage,
                eventsToRemoveFromDone,
                eventsToRemoveFromTodo,
                eventsToDecrementVisitCount,
            };
        },
        [removeFromDone, userAlerts]
    );

    const values = {
        userAlerts,
        addTodoEvent,
        closeEvent,
        removeFromDone,
        commitUpdateUserAlerts,
        decrementVisitCount,
        removeFromTodo,
        refreshUserAlerts,
        allStagedEvents,
        setAllStagedEvents,
    };

    return <UserAlertsContext.Provider value={values}>{children}</UserAlertsContext.Provider>;
};

export const useUserAlertsSelector = <T,>(selector: (state: UserAlertsContextValue) => T): T =>
    useContextSelector(UserAlertsContext, selector);
