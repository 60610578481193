"use client";

import { FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import LazyLoaded from "@/components/LazyLoaded";
import WithOptionsMenu, { WithMenuOption } from "@/components/WithOptionsMenu";
import { ASSETS_URL } from "@/config/deployConstants";
import { AuthEvent } from "@/features/Auth/AuthPopup/utils/constants";
import { useCheckAuth } from "@/hooks/useCheckAuth";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { borderRadius } from "@/utils/borderRadius";
import { createQueryStrings, isDarkColor } from "@/utils/genericUtils";
import { iconSizes } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";
import { AccountType, ItemType } from "@knowt/syncing/graphql/schema";
import { useFolder } from "@knowt/syncing/hooks/folders/useFolders";
import { Portal } from "@mui/base/Portal";
import { Plus } from "lucide-react";
import dynamic from "next/dynamic";
import Image from "next/image";
import { useRouter } from "@bprogress/next";
import React, { useState } from "react";
import ActionArrowRight from "../Circular/styled/ActionArrowRight";
import CircularOutlineIcon from "../CircularButton/styled/CircularOutlineIcon";
import FilledIconTextButton from "../CircularButton/styled/FilledIconTextButton";
import CreateContentPopup from "../popupWithSidebar/CreateContentPopup";
import OutlineTextButton from "../CircularButton/styled/OutlineTextButton";
import { spacing } from "@/utils/spacing";
import CreateClassPopup from "../Popup/CreateClassPopup";
import SparkleIcon from "../icons/SparkleIcon";

const ImportFromQuizletPopup = dynamic(() => import("@/components/Popup/ImportFromQuizletPopup"));
const ImportPopup = dynamic(() => import("@/components/Popup/ImportPopup"));

const AddClassFilePopup = dynamic(
    () => import("@/features/UserContentManagement/ClassPage/TeacherView/components/popups/AddClassFilePopup")
);

const MoveFileToClassPopup = dynamic(
    () => import("@/features/UserContentManagement/ClassPage/TeacherView/components/popups/MoveFileToClassPopup")
);

const noteIcon = `${ASSETS_URL}/images/note-icon.svg`;
const flashcardIcon = `${ASSETS_URL}/images/flashcard-icon.svg`;
const videoIcon = `${ASSETS_URL}/images/video-icon.svg`;
const audioIcon = `${ASSETS_URL}/images/audio-icon.svg`;
const quizletIcon = `${ASSETS_URL}/images/quizlet-icon.svg`;
const importIcon = `${ASSETS_URL}/images/import-icon.svg`;
const pdfIcon = `${ASSETS_URL}/images/pdf-icon.svg`;
const blackBoardIcon = `/images/blackboard.svg`;
const moveFileIcon = `${ASSETS_URL}/images/move-file-icon.svg`;
const joinClassIcon = `${ASSETS_URL}/images/join_class_icon.svg`;

export const CREATE_NEW_CONTENT_BTN_ID = "create-new-content-btn";

const CreateOption = ({
    id,
    label,
    image,
    background,
    icon,
}: { id?: string; label: string; image: string; background: string; icon?: React.ReactNode }) => {
    return (
        <FlexRowAlignCenter style={{ justifyContent: "space-between" }} id={id}>
            <FlexRowAlignCenter style={{ gap: "1rem" }}>
                <FlexColumnAlignJustifyCenter
                    style={{
                        width: "30px",
                        height: "30px",
                        background,
                        borderRadius: borderRadius.circular,
                        flexShrink: 0,
                    }}>
                    {icon || <Image src={image} alt={label} width={17} height={17} style={{ objectFit: "contain" }} />}
                </FlexColumnAlignJustifyCenter>
                <span style={{ fontSize: "1.5rem", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>
                    {label}
                </span>
            </FlexRowAlignCenter>
            <ActionArrowRight style={{ backgroundColor: undefined }} />
        </FlexRowAlignCenter>
    );
};

type CreateNewContentButtonWithHandlerProps = {
    onlyShowIcon?: boolean;
    /** either to add create/join class option or not */
    classMode?: boolean;
    /** override the button color */
    color?: string;
    /** if present then the created note/flashcards will have `classId` in the obj */
    classId?: string;
    /** if present then the created note/flashcards will have `folderId` in the obj */
    folderId?: string;
    /** if true then only the text will be shown */
    onlyShowText?: boolean;
};

export const CreateNewContentButtonWithHandler = ({
    onlyShowIcon = false,
    classMode = false,
    color,
    classId,
    folderId,
    onlyShowText = false,
}: CreateNewContentButtonWithHandlerProps) => {
    const { user, userId, isTeacher } = useCurrentUser();

    const router = useRouter();
    const { checkAuth } = useCheckAuth();
    const { folder } = useFolder({ folderId });

    const [isCreateContentPopupOpen, setIsCreateContentPopupOpen] = useState(false);

    const [isImportFlashcardsPopupOpen, setIsImportFlashcardsPopupOpen] = useState(false);
    const [shouldBypassChromeExtensionPopup, setShouldBypassChromeExtensionPopup] = useState(false);

    const [isImportNotesPopupOpen, setIsImportNotesPopupOpen] = useState(false);
    const [isCreateClassPopupOpen, setIsCreateClassPopupOpen] = useState(false);

    //in Class Mode
    const [isAddClassFilePopupOpen, setIsAddClassFilePopupOpen] = useState(false);
    const [isMoveFileToClassPopupOpen, setIsMoveFileToClassPopupOpen] = useState(false);
    const [desiredFileType, setDesiredFileType] = useState<ItemType | null>(null);

    const parentSharedSections = folder?.sections;

    const buttonText = classId ? "Add files" : classMode ? (isTeacher ? "Create or join" : "Join a class") : "Create";

    const aiSummarizationOptions: WithMenuOption[] = [
        ...(user?.accountType === AccountType.Teacher
            ? [
                  {
                      label: "New AI Tools for Teachers",
                      node: (
                          <CreateOption
                              label={"New AI Tools for Teachers"}
                              icon={<SparkleIcon size={iconSizes.SM} />}
                              background={themeColors.primaryLight}
                          />
                      ),
                      onClick: () => {
                          checkAuth({
                              event: AuthEvent.AI_TOOLS,
                              onAuthSuccess: () => router.push(`/free-ai-tools-for-teachers`),
                          });
                      },
                  },
              ]
            : []),
        {
            label: "AI Video Summarizer",
            node: <CreateOption label={"AI Video Summarizer"} image={videoIcon} background={themeColors.aiGradient} />,
            onClick: () => router.push(`/ai-video-summarizer${createQueryStrings({ folderId, classId })}`),
        },
        {
            label: "AI Lecture Note Taker",
            node: (
                <CreateOption label={"AI Lecture Note Taker"} image={audioIcon} background={themeColors.aiGradient} />
            ),
            onClick: () => router.push(`/ai-lecture-note-taker${createQueryStrings({ folderId, classId })}`),
        },
        {
            label: "AI PDF Summarizer",
            node: <CreateOption label={"AI PDF Summarizer"} image={pdfIcon} background={themeColors.aiGradient} />,
            onClick: () => router.push(`/ai-pdf-summarizer${createQueryStrings({ folderId, classId })}`),
        },
        {
            label: "AI PPT Summarizer",
            node: (
                <CreateOption
                    label={"AI PPT Summarizer"}
                    image={`/images/ppt-icon.svg`}
                    background={themeColors.aiGradient}
                />
            ),
            onClick: () => router.push(`/ai-powerpoint-summarizer${createQueryStrings({ folderId, classId })}`),
        },
    ];

    const createOptions: WithMenuOption[] = [
        ...(!user || user?.accountType === AccountType.Student
            ? [
                  {
                      label: "Join class",
                      node: (
                          <CreateOption
                              id="join-class-button"
                              label={"Join class"}
                              image={blackBoardIcon}
                              background={themeColors.primaryLight}
                          />
                      ),
                      onClick: () => {
                          checkAuth({
                              event: AuthEvent.JOIN_CLASS,
                              onAuthSuccess: () => router.push("/class/join"),
                          });
                      },
                      menuItemProps: {
                          sx: { borderBottom: `1px solid ${themeColors.neutral1}`, paddingBlock: "1.5rem" },
                      },
                  },
              ]
            : []),
        ...(user?.accountType === AccountType.Teacher
            ? [
                  {
                      label: "Create class",
                      node: (
                          <CreateOption
                              id="create-class-button"
                              label={"Create class"}
                              image={blackBoardIcon}
                              background={themeColors.primaryLight}
                          />
                      ),
                      onClick: () => setIsCreateClassPopupOpen(true),
                      menuItemProps: {
                          sx: { paddingTop: "1.5rem" },
                      },
                  },
                  {
                      label: "Join class",
                      node: (
                          <CreateOption
                              label={"Join class"}
                              image={joinClassIcon}
                              background={themeColors.primaryLight}
                          />
                      ),
                      onClick: () => {
                          checkAuth({
                              event: AuthEvent.JOIN_CLASS,
                              onAuthSuccess: () => router.push("/class/join"),
                          });
                      },
                      menuItemProps: {
                          sx: {
                              borderBottom: isTeacher ? "none" : `1px solid ${themeColors.neutral1}`,
                              paddingBlock: "1.5rem",
                          },
                      },
                  },
              ]
            : []),
        ...(!classMode
            ? [
                  {
                      label: "Notes",
                      node: <CreateOption label={"Notes"} image={noteIcon} background={themeColors.docsLight} />,
                      onClick: async () => {
                          if (!user) {
                              router.push("/ai-notes");
                              return;
                          }

                          const { createAndOpenNewNote } = await import("@/utils/navigation/notes");

                          const props: Record<string, string> = {};
                          if (folderId) {
                              props.folderId = folderId;
                          }
                          if (classId) {
                              props.classId = classId;
                          }

                          await createAndOpenNewNote(props, user, router);
                      },
                      menuItemProps: {
                          sx: {
                              paddingTop: "1.5rem",
                          },
                      },
                  },
                  {
                      label: "Flashcards",
                      node: (
                          <CreateOption
                              label={"Flashcards"}
                              image={flashcardIcon}
                              background={themeColors.flashcardsLight}
                          />
                      ),
                      onClick: async () => {
                          const isAuthenticated = await checkAuth({
                              event: AuthEvent.CREATE_FLASHCARDS,
                          });

                          if (!isAuthenticated) return;

                          const { makeStandaloneFlashcardSet } = await import("@/hooks/flashcards/utils");

                          const { flashcardSetId } = await makeStandaloneFlashcardSet({
                              userId,
                              ...(folderId
                                  ? {
                                        folderId,
                                        public: folder?.public,
                                        password: folder?.password,
                                    }
                                  : {}),
                              ...(classId ? { classId } : {}),
                          });

                          router.push(`/flashcards/${flashcardSetId}/edit`);
                      },
                  },
                  ...aiSummarizationOptions,
                  {
                      label: "Import from Quizlet",
                      node: (
                          <CreateOption
                              label={"Import from Quizlet"}
                              image={quizletIcon}
                              background={themeColors.docsLight}
                          />
                      ),
                      onClick: () => {
                          checkAuth({
                              event: AuthEvent.IMPORT_FLASHCARDS,
                              onAuthSuccess: () => {
                                  setShouldBypassChromeExtensionPopup(false);
                                  setIsImportFlashcardsPopupOpen(true);
                              },
                          });
                      },
                      menuItemProps: {
                          sx: {
                              paddingBottom: "1.5rem",
                          },
                      },
                  },
                  {
                      label: "Import Manually",
                      node: (
                          <CreateOption
                              label={"Import Manually"}
                              image={importIcon}
                              background={themeColors.docsLight}
                          />
                      ),
                      onClick: () => {
                          checkAuth({
                              event: AuthEvent.IMPORT_FLASHCARDS,
                              onAuthSuccess: () => {
                                  setShouldBypassChromeExtensionPopup(true);
                                  setIsImportFlashcardsPopupOpen(true);
                              },
                          });
                      },
                      menuItemProps: {
                          sx: {
                              paddingBottom: "1.5rem",
                          },
                      },
                  },
              ]
            : []),
    ];

    const addClassFileOptions: WithMenuOption[] = [
        {
            label: "Move existing file",
            // FIXME check if this background is correct
            node: (
                <CreateOption label={"Move existing file"} image={moveFileIcon} background={themeColors.aiGradient} />
            ),
            onClick: () => {
                checkAuth({
                    event: AuthEvent.ADD_CLASS_FILE,
                    onAuthSuccess: () => setIsMoveFileToClassPopupOpen(true),
                });
            },
        },
        {
            label: "Notes",
            node: <CreateOption label={"Create Notes"} image={noteIcon} background={themeColors.docsLight} />,
            onClick: () => {
                checkAuth({
                    event: AuthEvent.ADD_CLASS_FILE,
                    onAuthSuccess: () => {
                        setDesiredFileType(ItemType.NOTE);
                        setIsAddClassFilePopupOpen(true);
                    },
                });
            },
        },
        {
            label: "Flashcards",
            node: (
                <CreateOption
                    label={"Create Flashcards"}
                    image={flashcardIcon}
                    background={themeColors.flashcardsLight}
                />
            ),
            onClick: () => {
                checkAuth({
                    event: AuthEvent.ADD_CLASS_FILE,
                    onAuthSuccess: () => {
                        setDesiredFileType(ItemType.FLASHCARDSET);
                        setIsAddClassFilePopupOpen(true);
                    },
                });
            },
        },
        ...aiSummarizationOptions,
        {
            label: "Import from Quizlet",
            node: <CreateOption label={"Import from Quizlet"} image={quizletIcon} background={themeColors.docsLight} />,
            onClick: () => {
                checkAuth({
                    event: AuthEvent.ADD_CLASS_FILE,
                    onAuthSuccess: () => {
                        setShouldBypassChromeExtensionPopup(false);
                        setIsImportFlashcardsPopupOpen(true);
                    },
                });
            },
        },
    ];

    const renderButton = (openMenu: (e: React.MouseEvent) => void) => {
        const iconColor = color
            ? isDarkColor(color)
                ? themeColors.pureWhite
                : themeColors.pureBlack
            : themeColors.primaryDark;

        if (onlyShowIcon) {
            return (
                <CircularOutlineIcon
                    id={CREATE_NEW_CONTENT_BTN_ID + (classMode ? "-class" : "")}
                    size={iconSizes.MD_S}
                    Icon={Plus}
                    onClick={e => {
                        e.stopPropagation();
                        // there is only one menu item -> do the action directly
                        if (classMode) {
                            if (!user || user?.accountType === AccountType.Student) {
                                checkAuth({
                                    event: AuthEvent.JOIN_CLASS,
                                    onAuthSuccess: () => router.push("/class/join"),
                                });
                            } else {
                                openMenu(e);
                            }
                        } else {
                            setIsCreateContentPopupOpen(true);
                        }
                    }}
                    buttonColor={color ?? themeColors.neutralBlack}
                    color={color ? iconColor : themeColors.neutralWhite}
                    hoverColor={themeColors.neutralBlack}
                    tooltip={classMode ? (isTeacher ? `Create a new class` : `Join a class`) : `Create new content`}
                    style={{ border: `2px solid #28C3E1` }}
                    borderColor={themeColors.interactiveBorder}
                />
            );
        }

        if (onlyShowText) {
            return (
                <OutlineTextButton
                    id={CREATE_NEW_CONTENT_BTN_ID + (classMode ? "-class" : "")}
                    onClick={e => {
                        e.stopPropagation();
                        if (classMode) {
                            if (!user || user?.accountType === AccountType.Student) {
                                checkAuth({
                                    event: AuthEvent.JOIN_CLASS,
                                    onAuthSuccess: () => router.push("/class/join"),
                                });
                            } else {
                                openMenu(e);
                            }
                        } else {
                            setIsCreateContentPopupOpen(true);
                        }
                    }}
                    style={{
                        padding: `${spacing.MD}`,
                    }}
                    className="bodyBold2"
                    sx={{
                        backgroundColor: themeColors.primary,
                        color: themeColors.primaryDark,
                        border: `1px solid ${themeColors.primary}`,
                        marginTop: spacing.SM,
                        transition: null,
                    }}>
                    {buttonText}
                </OutlineTextButton>
            );
        }

        return (
            <div style={{ position: "relative" }}>
                <FilledIconTextButton
                    id={CREATE_NEW_CONTENT_BTN_ID + (classMode ? "-class" : "")}
                    Icon={Plus}
                    primaryBg={color ?? themeColors.neutralBlack}
                    primaryColor={
                        color
                            ? isDarkColor(color)
                                ? themeColors.pureWhite
                                : themeColors.pureBlack
                            : themeColors.neutralWhite
                    }
                    secondaryBg={color || themeColors.createButtonBlueHover}
                    secondaryColor={themeColors.neutralBlack}
                    iconColor={
                        color
                            ? isDarkColor(color)
                                ? themeColors.pureWhite
                                : themeColors.pureBlack
                            : themeColors.neutralWhite
                    }
                    iconHoverColor={themeColors.neutralBlack}
                    onClick={e => {
                        e.stopPropagation();
                        // there is only one menu item -> do the action directly
                        if (classMode) {
                            if (!user || user?.accountType === AccountType.Student) {
                                checkAuth({
                                    event: AuthEvent.JOIN_CLASS,
                                    onAuthSuccess: () => router.push("/class/join"),
                                });
                            } else {
                                openMenu(e);
                            }
                        } else {
                            setIsCreateContentPopupOpen(true);
                        }
                    }}
                    style={{
                        whiteSpace: "nowrap",
                    }}>
                    {buttonText}
                </FilledIconTextButton>
            </div>
        );
    };

    return (
        <React.Fragment>
            <WithOptionsMenu
                options={classId ? addClassFileOptions : createOptions}
                menuProps={{
                    PaperProps: {
                        sx: {
                            marginTop: "1rem",
                            borderRadius: "2rem",
                            overflow: "hidden",
                            "& ul": {
                                padding: "1rem 0 !important",
                            },
                        },
                    },
                    anchorOrigin: { vertical: "bottom", horizontal: onlyShowIcon ? "right" : "left" },
                    transformOrigin: { vertical: "top", horizontal: onlyShowIcon ? "right" : "left" },
                }}
                menuItemProps={{ sx: { padding: "1rem 2rem", width: "35rem", margin: "0", height: "5.05rem" } }}>
                {({ openMenu }) => renderButton(openMenu)}
            </WithOptionsMenu>
            <LazyLoaded load={isImportFlashcardsPopupOpen}>
                <Portal>
                    <ImportFromQuizletPopup
                        isOpen={isImportFlashcardsPopupOpen}
                        onClose={() => setIsImportFlashcardsPopupOpen(false)}
                        bypassChromeExtensionPopup={shouldBypassChromeExtensionPopup}
                        onImportClick={async ({ title, flashcards }) => {
                            const { makeStandaloneFlashcardSet } = await import("@/hooks/flashcards/utils");

                            const { flashcardSetId } = await makeStandaloneFlashcardSet({
                                userId,
                                title,
                                flashcards,
                                quizletUrl: "MANUAL_IMPORT",
                                ...(folderId
                                    ? {
                                          folderId,
                                          public: folder?.public,
                                          password: folder?.password,
                                          sections: folder?.sections,
                                      }
                                    : {}),
                                ...(classId ? { classId } : {}),
                            });

                            setIsImportFlashcardsPopupOpen(false);
                            router.push(`/flashcards/${flashcardSetId}/edit`);
                        }}
                    />
                </Portal>
            </LazyLoaded>
            <LazyLoaded load={isImportNotesPopupOpen}>
                <Portal>
                    <ImportPopup
                        isOpen={isImportNotesPopupOpen}
                        close={() => setIsImportNotesPopupOpen(false)}
                        importNewNote={async ({ content, title }) => {
                            const { createAndOpenNewNote } = await import("@/utils/navigation/notes");
                            await createAndOpenNewNote(
                                { content, title, ...(folderId ? { folderId } : {}) },
                                user,
                                router
                            );
                        }}
                    />
                </Portal>
            </LazyLoaded>
            <LazyLoaded load={isAddClassFilePopupOpen}>
                <Portal>
                    <AddClassFilePopup
                        isOpen={isAddClassFilePopupOpen}
                        onClose={() => setIsAddClassFilePopupOpen(false)}
                        classId={classId}
                        folderId={folderId}
                        sharedSections={parentSharedSections}
                        openMoveFileToClassPopup={() => {
                            setIsAddClassFilePopupOpen(false);
                            setIsMoveFileToClassPopupOpen(true);
                        }}
                        itemType={desiredFileType}
                    />
                </Portal>
            </LazyLoaded>
            <LazyLoaded load={isMoveFileToClassPopupOpen}>
                <Portal>
                    <MoveFileToClassPopup
                        isOpen={isMoveFileToClassPopupOpen}
                        onClose={() => setIsMoveFileToClassPopupOpen(false)}
                        classId={classId}
                        folderId={folderId}
                        sharedSections={folder?.sections}
                    />
                </Portal>
            </LazyLoaded>
            <LazyLoaded load={isCreateContentPopupOpen}>
                <CreateContentPopup
                    isOpen={isCreateContentPopupOpen}
                    onClose={() => setIsCreateContentPopupOpen(false)}
                />
            </LazyLoaded>
            <LazyLoaded load={isCreateClassPopupOpen}>
                <CreateClassPopup isOpen={isCreateClassPopupOpen} onClose={() => setIsCreateClassPopupOpen(false)} />
            </LazyLoaded>
        </React.Fragment>
    );
};
