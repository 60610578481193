import { Class, ClassPermission, ClassRole, Folder } from "@/graphql/schema";
import { callGetClass, callListClasses, getClassPermission, getClassRole } from "@/hooks/classes/graphqlUtils";
import { resolveClassSWRKey, resolveClassesSWRKey } from "@/hooks/classes/utils";
import { useSWRImmutable } from "@/hooks/swr/immutable";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { useMemo } from "react";

export const useClasses = ({
    isEnabled = true,
    fallbackData,
}: { isEnabled?: boolean; fallbackData?: Record<string, Class> } = {}) => {
    const { userId } = useCurrentUser();

    const {
        data: classes,
        error,
        mutate,
    } = useSWRImmutable(
        resolveClassesSWRKey({
            userId,
            isEnabled,
        }),
        () => callListClasses({}),
        { fallbackData }
    );

    return { classes, error, isLoading: isEnabled && !error && !classes, mutate };
};

export const useClass = ({
    classId,
    fallbackData = undefined,
    isEnabled = true,
}: {
    classId?: string | null | undefined;
    fallbackData?: Class | undefined;
    isEnabled?: boolean;
}) => {
    const { userId } = useCurrentUser();

    const {
        data: course,
        error,
        mutate,
    } = useSWRImmutable(resolveClassSWRKey({ classId, isEnabled }), () => callGetClass({ classId }), {
        fallbackData,
    });

    const {
        currentUserClassRole,
        isCurrentUserOwnerOfClass,
        isCurrentUserProperOwnerOfClass,
        isCurrentUserNotStudentOfClass,
    } = useMemo(() => {
        const currentUserClassRole = getClassRole(course, userId);

        return {
            currentUserClassRole,
            isCurrentUserProperOwnerOfClass: currentUserClassRole === ClassRole.OWNER,
            isCurrentUserOwnerOfClass: [ClassRole.OWNER, ClassRole.CO_OWNER].includes(
                currentUserClassRole || ("" as ClassRole)
            ),
            isCurrentUserNotStudentOfClass: currentUserClassRole !== ClassRole.STUDENT,
        };
    }, [course, userId]);

    return {
        course: classId === null ? null : course,
        isLoading: !course && !error,
        permission: getClassPermission({ course, userId }),
        currentUserClassRole,
        isCurrentUserProperOwnerOfClass,
        isCurrentUserOwnerOfClass,
        isCurrentUserNotStudentOfClass,
        mutate,
    };
};

export const useClassFolderPermission = ({
    folder,
    classId,
    isEnabled = false,
}: {
    folder?: Folder;
    classId?: string | null;
    isEnabled: boolean;
}) => {
    const { userId } = useCurrentUser();
    const { permission } = useClass({ classId, isEnabled: isEnabled && !!classId });

    return {
        isReadOnlyClassFolder: !!folder?.classId && permission !== ClassPermission.ALL && folder.userId !== userId,
    };
};
