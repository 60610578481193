import Circular from "@/components/Circular";
import CircularButton from "@/components/CircularButton";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import Select, { SelectProps } from "@/components/Select";
import ToggleSwitch from "@/components/ToggleSwitch";
import ClickableText from "@/components/styled/button/ClickableText";
import { ASSETS_URL } from "@/config/deployConstants";
import { AuthEvent } from "@/features/Auth/AuthPopup/utils/constants";
import { useCheckAuth } from "@/hooks/useCheckAuth";
import importFromDrive, { DriveImportType } from "@/utils/Google/Picker";
import { borderRadius } from "@/utils/borderRadius";
import { iconSizes } from "@/utils/iconProps";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { SetState } from "@knowt/syncing/types/common";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import clsx from "clsx";
import { Check, CloudUpload, Folders, RotateCcw, SparkleIcon, X } from "lucide-react";
import Image from "next/image";
import { useEffect, useMemo, useRef, useState } from "react";
import { Accept as DropzoneAccept, useDropzone } from "react-dropzone";
import toast from "react-hot-toast";

const videoIcon = `${ASSETS_URL}/images/video-icon.svg`;
const audioIcon = `${ASSETS_URL}/images/audio-icon.svg`;
const pdfIcon = `${ASSETS_URL}/images/pdf-icon.svg`;
const xlsIcon = `${ASSETS_URL}/images/xlsx-icon.svg`;
const pptIcon = `${ASSETS_URL}/images/pptx-icon.svg`;
const pptxIcon = `${ASSETS_URL}/images/google-slides.svg`;
const xlsxIcon = `${ASSETS_URL}/images/xlsx-icon.svg`;

export const AIToolsSelect = <T extends { label: string; value: unknown }>({
    label,
    labelClassName,
    isHorizontalLayout,
    isMandatory,
    isShowingMissingInputs,
    id,
    ...props
}: {
    label: string;
    labelClassName?: string;
    isHorizontalLayout?: boolean;
    isMandatory?: boolean;
    isShowingMissingInputs?: boolean;
    id?: string;
} & SelectProps<T>) => {
    const [isMissing, setIsMissing] = useState<boolean>(false);
    useEffect(() => props.onChange(props.selected), []); // edge case : selected value type was string and its default value is ""

    useEffect(() => {
        if (isShowingMissingInputs && isMandatory) {
            if (!props.selected.value) {
                setIsMissing(true);
            } else {
                setIsMissing(false);
            }
        }
    }, [isShowingMissingInputs, isMandatory, props.selected]);

    return (
        <FlexColumn
            style={{
                gap: spacing.XS_2,
                flexDirection: isHorizontalLayout ? "row" : "column",
                justifyContent: "space-between",
                alignItems: isHorizontalLayout ? "center" : "flex-start",
            }}>
            <FlexRowAlignCenter style={{ gap: spacing.XS }}>
                <span className={labelClassName ?? "bodyBold1"}>{label}</span>
                {isMandatory && (
                    <span className="bodyBold1" style={{ color: themeColors.errorPrimary }}>
                        *
                    </span>
                )}
            </FlexRowAlignCenter>
            <Select
                id={id}
                btnSx={{
                    gap: spacing.XS,
                    width: isHorizontalLayout ? "unset" : "100%",
                    minWidth: "15rem",
                    justifyContent: "space-between",
                    borderRadius: borderRadius.shortInput,
                    border: isMissing
                        ? `2px solid ${themeColors.errorPrimary}`
                        : `1px solid ${themeColors.neutralBlack}`,
                    fontWeight: "400",
                }}
                menuSx={{
                    minWidth: "12rem",
                    width: "fit-content",
                }}
                transitionOrigin={{
                    anchorOrigin: { vertical: "bottom", horizontal: "right" },
                    transformOrigin: { vertical: "top", horizontal: "right" },
                }}
                {...props}
            />
        </FlexColumn>
    );
};

const AiResultOption = ({ onClick, children }) => {
    return (
        <Box
            onClick={onClick}
            sx={{
                display: "flex",
                width: "100%",
                borderRadius: "0.6rem",
                padding: "0.4rem 0.8rem",
                justifyContent: "flex-start",
                fontSize: "1.4rem",
                fontWeight: 400,
                lineHeight: "2rem",
                gap: spacing.XS_2,
                cursor: "pointer",

                "&:hover": {
                    backgroundColor: themeColors.neutral1,
                },
            }}>
            {children}
        </Box>
    );
};

//TODO:ADD audio transcript
export const AIToolsTextRecordArea = ({
    label,
    placeholder,
    textAreaStyle,
    handleHelpFillField,
    handWrittenValue,
    onTextChange,
    containerStyle,
    disabledAI,
    isMandatory = false,
    isShowingMissingInputs = false,
    generationOptionsStyle,
    ...props
}: {
    label?: string;
    placeholder?: string;
    handWrittenValue: string;
    onTextChange: (value: string) => void;
    handleHelpFillField?: (description: string) => Promise<string>;
    containerStyle?: React.CSSProperties;
    textAreaStyle?: React.CSSProperties;
    disabledAI?: boolean;
    isMandatory?: boolean;
    isShowingMissingInputs?: boolean;
    generationOptionsStyle?: React.CSSProperties;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>) => {
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const { checkAuth } = useCheckAuth();
    const [isGenerationEnded, setIsGenerationEnded] = useState(false);

    const handWrittenText = useMemo(() => handWrittenValue, [handWrittenValue]);
    const [generatedImprovedText, setGeneratedImprovedText] = useState<string | null>(null);
    const [isMissing, setIsMissing] = useState<boolean>(false);

    const [isGenerating, setIsGenerating] = useState(false);

    const adjustTextareaHeight = (textarea: HTMLTextAreaElement) => {
        textarea.style.height = "auto"; // Reset height
        textarea.style.height = `${textarea.scrollHeight}px`; // Adjust to content
    };

    const shoudlDisplayImproveButton = !!handWrittenText && !isGenerating && !isGenerationEnded && !disabledAI;

    const handleImproveText = async () => {
        const isAuthenticated = await checkAuth({
            event: AuthEvent.LANDING_TEACHER_TOOLS,
        });

        if (!isAuthenticated) return;

        if (typeof handWrittenText !== "string" || !handleHelpFillField) {
            return;
        }
        setGeneratedImprovedText(null);
        setIsGenerationEnded(false);
        setIsGenerating(true);

        const generatedInput = await handleHelpFillField(handWrittenText);

        setGeneratedImprovedText(generatedInput?.length ? generatedInput : handWrittenText);

        setIsGenerating(false);
        setIsGenerationEnded(true);
    };

    const handleDiscard = () => {
        setGeneratedImprovedText(null);
        setIsGenerationEnded(false);
    };

    const handleAccept = () => {
        if (!generatedImprovedText) return;

        onTextChange(generatedImprovedText);
        setGeneratedImprovedText(null);
        setIsGenerationEnded(false);
    };

    useEffect(() => {
        if (!isShowingMissingInputs || !isMandatory) return;

        if (handWrittenText === "" || !handWrittenText) {
            setIsMissing(true);
        }
    }, [isShowingMissingInputs, isMandatory, handWrittenText]);

    useEffect(() => {
        if (isMissing && handWrittenText !== "") {
            setIsMissing(false);
        }
    }, [handWrittenText]);

    useEffect(() => {
        if (textareaRef.current) {
            adjustTextareaHeight(textareaRef.current);
        }
    }, [generatedImprovedText]);

    return (
        <FlexColumn style={{ gap: spacing.XS_2, position: "relative" }}>
            <FlexRowAlignCenter style={{ gap: spacing.XS }}>
                <span className="bodyBold1">{label}</span>
                {isMandatory && label && (
                    <span className="bodyBold1" style={{ color: themeColors.errorPrimary }}>
                        *
                    </span>
                )}
            </FlexRowAlignCenter>
            <FlexColumn
                style={{
                    background: isGenerating || isGenerationEnded ? themeColors.aiGradient : themeColors.background,
                    borderRadius: borderRadius.card,
                    padding: "2.5px",
                    border: isMissing ? `2px solid ${themeColors.errorPrimary}` : "none",
                }}>
                <FlexColumn
                    style={{
                        position: "relative",
                        gap: spacing.XS_2,
                        padding: spacing.SM,
                        alignItems: "flex-start",
                        borderRadius: borderRadius.card,
                        backgroundColor: themeColors.background,
                        paddingBottom: shoudlDisplayImproveButton || isGenerating ? "6rem" : spacing.SM,
                        ...containerStyle,
                    }}>
                    <textarea
                        ref={textareaRef}
                        className={clsx(["scrollbar-thin", "secondaryText1"])}
                        placeholder={placeholder ?? "Enter text here"}
                        style={{
                            fontFamily: "var(--knowt-font-name)",
                            resize: "none",
                            width: "100%",
                            outline: "none",
                            fontSize: "1.6rem",
                            color:
                                (isGenerating && !!generatedImprovedText) || isGenerationEnded
                                    ? themeColors.ai
                                    : themeColors.neutralBlack,
                            backgroundColor: "transparent",
                            border: "none",
                            ...textAreaStyle,
                        }}
                        value={generatedImprovedText ?? handWrittenText}
                        onChange={e => {
                            onTextChange(e.target.value);

                            adjustTextareaHeight(e.target);
                        }}
                        {...props}
                    />
                    {shoudlDisplayImproveButton && (
                        <CircularRectTextButton
                            className="secondaryTextBold1"
                            onClick={handleImproveText}
                            style={{
                                position: "absolute",
                                bottom: "1.2rem",
                                gap: "0.5rem",
                                padding: "0.5rem 0.8rem",
                                color: themeColors.ai,
                                backgroundColor: themeColors.pdfLight,
                            }}
                            tabIndex={-1}
                            sx={{
                                "&:hover": {
                                    backgroundColor: themeColors.pdfLight,
                                    // boxShadow: "inset 0 0 0 10em rgba(0, 0, 0, 0.1)",
                                    outline: `1px solid ${themeColors.pdfDark}`,
                                },
                            }}>
                            <SparkleIcon size={iconSizes.MD_S} /> Improve this for me
                        </CircularRectTextButton>
                    )}

                    {isGenerating && (
                        <FlexRowAlignCenter
                            className="secondaryTextBold1"
                            style={{
                                borderRadius: "3.4rem",
                                position: "absolute",
                                bottom: "1.2rem",
                                gap: "0.5rem",
                                padding: "0.5rem 0.8rem",
                                color: themeColors.ai,
                                backgroundColor: themeColors.pdfLight,
                            }}>
                            <CircularProgress
                                size={18}
                                style={{
                                    color: themeColors.ai,
                                }}
                            />{" "}
                            Spicing up your writing...
                        </FlexRowAlignCenter>
                    )}

                    {isGenerationEnded && (
                        <FlexColumn
                            style={{
                                position: "absolute",
                                right: "-17rem",
                                top: "auto",
                                width: "16rem",
                                gap: "0.4rem",
                                padding: spacing.XS,
                                borderRadius: "1.2rem",
                                backgroundColor: themeColors.neutralWhite,
                                boxShadow: "0px 1px 16px 0px rgba(0, 0, 0, 0.12)",
                                ...generationOptionsStyle,
                            }}>
                            <AiResultOption onClick={handleAccept}>
                                <Check size={20} />
                                {"Accept"}
                            </AiResultOption>

                            <AiResultOption onClick={handleDiscard}>
                                <X size={20} />
                                {"Discard"}
                            </AiResultOption>

                            <AiResultOption onClick={handleImproveText}>
                                <RotateCcw size={20} />
                                {"Try again"}
                            </AiResultOption>
                        </FlexColumn>
                    )}
                    {/* <CircularButton
                    style={{
                        position: "absolute",
                        bottom: 15,
                        right: 15,
                        backgroundColor: themeColors.neutral3,
                        width: "2.5rem",
                        height: "2.5rem",
                    }}>
                    <MicIcon color={themeColors.pureWhite} />
                </CircularButton> */}
                </FlexColumn>
            </FlexColumn>
        </FlexColumn>
    );
};

export const AINumberInput = ({
    label,
    unit,
    min = 0,
    max = 100,
    isMandatory,
    inputContainerStyle,
    inputClassName,
    ...props
}: {
    label?: string;
    unit?: string;
    min?: number;
    max?: number;
    isMandatory?: boolean;
    inputContainerStyle?: React.CSSProperties;
    inputClassName?: string;
} & React.InputHTMLAttributes<HTMLInputElement>) => {
    return (
        <FlexRowAlignCenter style={{ justifyContent: "space-between" }}>
            <FlexRowAlignCenter style={{ gap: spacing.XS }}>
                {label && <span className="bodyBold1">{label}</span>}
                {isMandatory && (
                    <span className="bodyBold1" style={{ color: themeColors.errorPrimary }}>
                        *
                    </span>
                )}
            </FlexRowAlignCenter>
            <FlexRowAlignCenter
                style={{
                    width: "15rem",
                    border: `1px solid ${themeColors.neutralBlack}`,
                    padding: `${spacing.XS_2} ${spacing.SM}`,
                    borderRadius: borderRadius.shortInput,
                    ...inputContainerStyle,
                }}>
                <input
                    type="number"
                    className={inputClassName ?? "body2"}
                    min={min}
                    max={max}
                    style={{
                        fontFamily: "var(--knowt-font-name)",
                        width: `${unit ? "3ch" : "100%"}`,
                        border: "none",
                        outline: "none",
                        color: themeColors.neutralBlack,
                        backgroundColor: "transparent",
                        textAlign: "left",
                        flexShrink: 0,
                    }}
                    onInput={e => {
                        const input = e.target as HTMLInputElement;

                        const value = Number.parseInt(input.value, 10);

                        if (value < 10) {
                            input.style.width = `${Math.max(input.value.length || 1, 2)}ch`;
                        }

                        if (value > 9) {
                            input.style.width = `${Math.max(input.value.length || 1, 3)}ch`;
                        }

                        if (value > 99) {
                            input.style.width = `${Math.max(input.value.length || 1, 4)}ch`;
                        }

                        // Enforce min/max constraints
                        if (value < (min || 0)) input.value = min.toString() || "0";
                        if (value > (max || 100)) input.value = max.toString() || "100";
                    }}
                    {...props}
                />
                <span className={inputClassName ?? "body2"}>{unit}</span>
            </FlexRowAlignCenter>
        </FlexRowAlignCenter>
    );
};

export const TextLengthInput = <T extends { label: string; value: unknown }>({
    label,
    inputProps,
    selectProps,
    isHorizontalLayout,
    isMandatory,
    id,
}: {
    label: string;
    isHorizontalLayout?: boolean;
    inputProps: React.InputHTMLAttributes<HTMLInputElement>;
    selectProps: SelectProps<T>;
    isMandatory?: boolean;
    id?: string;
}) => {
    return (
        <FlexColumn
            style={{
                gap: spacing.XS_2,
                flexDirection: isHorizontalLayout ? "row" : "column",
                justifyContent: "space-between",
                alignItems: isHorizontalLayout ? "center" : "flex-start",
            }}>
            <FlexRowAlignCenter style={{ gap: spacing.XS }}>
                <span className="bodyBold1">{label}</span>
                {isMandatory && (
                    <span className="bodyBold1" style={{ color: themeColors.errorPrimary }}>
                        *
                    </span>
                )}
            </FlexRowAlignCenter>
            <FlexRowAlignCenter style={{ gap: spacing.SM }}>
                <input
                    id={id}
                    type="number"
                    min={0}
                    max={100}
                    style={{
                        width: "3ch",
                        minWidth: "6rem",
                        border: "none",
                        outline: "none",
                        fontSize: "1.6rem",
                        color: themeColors.pureBlack,
                        backgroundColor: themeColors.background,
                        borderRadius: borderRadius.shortInput,
                        padding: "1rem 1.6rem",
                        textAlign: "center",
                    }}
                    {...inputProps}
                />
                <Select
                    btnSx={{
                        width: isHorizontalLayout ? "unset" : "100%",
                        minWidth: "15rem",
                        justifyContent: "space-between",
                        borderRadius: borderRadius.shortInput,
                        border: `1px solid ${themeColors.neutralBlack}`,
                        fontWeight: "400",
                    }}
                    menuSx={{
                        minWidth: "12rem",
                        width: "fit-content",
                    }}
                    transitionOrigin={{
                        anchorOrigin: { vertical: "bottom", horizontal: "right" },
                        transformOrigin: { vertical: "top", horizontal: "right" },
                    }}
                    {...selectProps}
                />
            </FlexRowAlignCenter>
        </FlexColumn>
    );
};

export const AIBooleanInput = ({ label, isMandatory, enabled, onToggleChange, id }) => {
    return (
        <FlexRowAlignCenter style={{ justifyContent: "space-between" }} id={id}>
            <FlexRowAlignCenter style={{ gap: spacing.XS }}>
                <span className="bodyBold1">{label}</span>
                {isMandatory && (
                    <span className="bodyBold1" style={{ color: themeColors.errorPrimary }}>
                        *
                    </span>
                )}
            </FlexRowAlignCenter>
            <ToggleSwitch checked={enabled} onChange={onToggleChange} />
        </FlexRowAlignCenter>
    );
};

const ACCEPTED_FILES: Record<string, string[]> = {
    "application/pdf": [".pdf"],
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
    "application/vnd.ms-powerpoint": [".ppt"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    "application/vnd.ms-excel": [".xls"],
};

const FILES_ICONS = {
    "application/pdf": pdfIcon,
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": pptxIcon,
    "application/vnd.ms-powerpoint": pptIcon,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": xlsxIcon,
    "application/vnd.ms-excel": xlsIcon,
    "video/*": videoIcon,
    "audio/*": audioIcon,
};

export const AIFilesDnDInput = ({
    id,
    label,
    labelClassName,
    acceptedFiles = ACCEPTED_FILES,
    droppedFile,
    setDroppedFile,
    isUploading,
    stopUpload,
}: {
    id?: string;
    label: string;
    labelClassName?: string;
    droppedFile: File | null;
    acceptedFiles?: DropzoneAccept;
    setDroppedFile: SetState<File | null>;
    isUploading: boolean;
    stopUpload: () => void;
}) => {
    const onDropAccepted = async acceptedFiles => {
        setDroppedFile(acceptedFiles[0]);
    };

    const onDropRejected = async rejectedFiles => {
        const { file } = rejectedFiles[0];
        toast.error(`File type not supported. ${file.name} cannot be uploaded.`);
    };

    const cancelDropHandler = event => {
        event.stopPropagation();
        stopUpload();
    };

    const getDroppedFileIcon = () => {
        return FILES_ICONS[droppedFile?.type || ""];
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDropAccepted,
        onDropRejected,
        multiple: false,
        accept: acceptedFiles,
        disabled: droppedFile instanceof File,
    });

    const renderEmptyDropZone = () => (
        <>
            <input {...getInputProps()} />
            <CircularRectTextButton
                id={id}
                className="bodyBold2"
                style={{
                    gap: spacing.XS,
                    padding: "1rem",
                    color: themeColors.neutralWhite,
                    backgroundColor: themeColors.neutralBlack,
                }}>
                <Folders size={21} strokeWidth={2} />
                {"Select files"}
            </CircularRectTextButton>
            <ClickableText
                className="secondaryTextBold1"
                onClick={e => {
                    e.stopPropagation();
                    importFromDrive(
                        null,
                        async ({ blob }) => {
                            setDroppedFile(blob);
                        },
                        DriveImportType.MEDIAS
                    );
                }}>
                Or, upload from Google Drive
            </ClickableText>
        </>
    );

    const renderDroppedFileInfo = () => (
        <>
            <FlexRowAlignCenter
                style={{
                    backgroundColor: themeColors.neutralWhite,
                    borderRadius: "2rem",
                    padding: spacing.SM,
                    width: "80%",
                    marginBottom: isUploading ? spacing.XS : spacing.MD,
                    borderWidth: "3px",
                    border: `1px solid ${themeColors.neutral1}`,
                }}>
                <FlexRowAlignCenter style={{ gap: spacing.MD, width: "100%" }}>
                    <Circular
                        radius="5rem"
                        style={{
                            borderRadius: "10px",
                            backgroundColor: droppedFile?.type?.startsWith("audio/")
                                ? themeColors.primaryLight
                                : "#F6E2E2",
                        }}>
                        <Image src={getDroppedFileIcon()} alt={"media"} width={"35"} height={"35"} />
                    </Circular>
                    <p className={"body2 clampText"} style={{ wordBreak: "break-word", textAlign: "left" }}>
                        {droppedFile?.name}
                    </p>
                </FlexRowAlignCenter>
                <CircularButton
                    onClick={cancelDropHandler}
                    style={{
                        padding: "0.4rem",
                    }}>
                    <X size={20} />
                </CircularButton>
            </FlexRowAlignCenter>

            {isUploading && (
                <FlexRowAlignCenter style={{ gap: spacing.XS }}>
                    <span className="secondaryText1" style={{ color: themeColors.ai }}>
                        Uploading file
                    </span>
                    <CircularProgress size={18} style={{ color: themeColors.ai }} />
                </FlexRowAlignCenter>
            )}

            <CircularRectTextButton
                className="secondaryTextBold1"
                onClick={cancelDropHandler}
                style={{
                    padding: "1rem",
                    border: `1px solid ${themeColors.neutralBlack}`,
                    gap: "0.5rem",
                }}>
                <CloudUpload size={18} strokeWidth={"2px"} />
                {"Choose different file"}
            </CircularRectTextButton>
        </>
    );

    return (
        <FlexColumn
            style={{
                gap: spacing.XS_2,
            }}>
            <span className={labelClassName ?? "bodyBold1"}>{label}</span>
            <FlexColumnAlignJustifyCenter
                {...getRootProps()}
                style={{
                    gap: "1rem",
                    width: "100%",
                    border: isUploading ? `2px solid ${themeColors.ai}` : `2px dashed ${themeColors.neutral2}`,
                    borderRadius: "1.9rem",
                    padding: spacing.MD,
                }}>
                {droppedFile ? renderDroppedFileInfo() : renderEmptyDropZone()}
            </FlexColumnAlignJustifyCenter>
        </FlexColumn>
    );
};
