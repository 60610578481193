import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import { themeColors } from "@/utils/themeColors";
import DialogCloseBtn from "@/features/ResponsiveDialog/styled/DialogCloseBtn";
import { useState } from "react";
import InputWithSearchbarIcon from "../../styled/input/InputWithSearchbarIcon";
import RepeatAutoFillGrid from "../../styled/div/RepeatAutoFillGrid";
import { CREATE_TOOL_ITEM, ToolType, useToolByGroup } from "./utils/useToolByGroup";
import {
    SidebarPopupBottomContainer,
    SidebarPopupMainContainer,
    SidebarPopupResponsiveDialog,
    SidebarPopupSideContainer,
} from "../styled/Containers";
import { SidebarPopupTabItem, SidebarPopupTabItemImg } from "../styled/Items";
import { SidebarPopupNextBtn } from "../styled/Buttons";
import ChatActivityPrompt from "@/features/ClassAssignment/components/ChatActivityPrompt";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { getCreateContentLabels } from "./utils/texts";
import ToolCard from "./components/ToolCard";
import useCombinedState from "@knowt/syncing/utils/hooks/useCombinedState";
import {
    AssignmentFileInput,
    AssignmentStatus,
    AssignmentType,
    ClassPermission,
    UpgradeEvent,
} from "@knowt/syncing/graphql/schema";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "@bprogress/next";
import toast from "react-hot-toast";
import Mountable from "@/components/styled/div/Mountable";
import LargeScreenLoading from "@/components/LargeScreenLoading";
import noop from "@knowt/syncing/utils/noop";
import { callCreateAssignmentFields } from "@knowt/syncing/hooks/assignments/graphqlUtils";
import { createAssignment } from "@knowt/syncing/hooks/assignments/utils";
import { useParams } from "next/navigation";
import { now } from "@knowt/syncing/utils/dateTimeUtils";
import LazyLoaded from "@/components/LazyLoaded";
import { updateFolderInFolderList } from "@knowt/syncing/hooks/folders/utils";
import { useFolder } from "@knowt/syncing/hooks/folders/useFolders";
import { useClass } from "@knowt/syncing/hooks/classes/useClass";
import dynamic from "next/dynamic";
import { callCreateFolder } from "@knowt/syncing/hooks/folders/graphqlUtils";
import { useUpgradePopupContextSelector } from "@/features/Payments/PaywallPopup/UpgradePopupContext";
import { S3UploadJobContextProvider } from "@knowt/syncing/context/S3UploadJobContext";
import br from "@/styles/breakpoints.module.css";

const ImportFromQuizletPopup = dynamic(() => import("@/components/Popup/ImportFromQuizletPopup"));
const MoveFileToClassPopup = dynamic(
    () => import("@/features/UserContentManagement/ClassPage/TeacherView/components/popups/MoveFileToClassPopup")
);
const NamingPopup = dynamic(() => import("@/components/Popup/NamingPopup"));
const CreateClassPopup = dynamic(() => import("@/components/Popup/CreateClassPopup"));

const ToolList = ({
    tools,
    folderId,
    classId,
    closePopup,
}: { tools: CREATE_TOOL_ITEM[]; folderId?: string; classId?: string; closePopup: () => void }) => {
    return (
        <RepeatAutoFillGrid
            itemMinWidth="19rem"
            style={{
                gridAutoRows: "1fr",
                boxSizing: "content-box",
                gap: "2.35rem 1.5rem",
                overflow: "auto",
                width: "100%",
                padding: "1.5rem 2rem 1.5rem",
                margin: "-0.5rem -2rem 0",
            }}
            className={"scrollbar"}>
            {tools.map((tool, i) => (
                <ToolCard
                    key={tool.title + i}
                    tool={tool}
                    folderId={folderId}
                    classId={classId}
                    closePopup={closePopup}
                />
            ))}
        </RepeatAutoFillGrid>
    );
};

const CreateContentHeader = ({
    title,
    desc,
    onClose,
    className,
}: { title: string; desc?: string; onClose: () => void; className?: string }) => {
    return (
        <FlexRowAlignCenter className={className} style={{ justifyContent: "space-between", marginBottom: "1.5rem" }}>
            <FlexColumn>
                <h1 className="bodyBold1">{title}</h1>
                <p className="secondaryTextBold1" style={{ color: themeColors.neutral2 }}>
                    {desc}
                </p>
            </FlexColumn>
            <DialogCloseBtn style={{ border: `1px solid ${themeColors.neutral1}` }} onClick={onClose} />
        </FlexRowAlignCenter>
    );
};

const CreateContentPopup = ({
    isOpen,
    onClose,
    initialToolIdx,
}: { isOpen: boolean; onClose: () => void; initialToolIdx?: number }) => {
    const router = useRouter();
    const [filter, setFilter] = useState("");
    const [currToolIdx, setCurrToolIdx] = useState(initialToolIdx ?? 0);
    const [isGenerating, setIsGenerating] = useState(false);

    const params = useParams<{ folderId: string; classId: string }>();
    const { userId, hasReachedAIUsage, isTeacher, sharingEnabled } = useCurrentUser();
    const openUpgradePopup = useUpgradePopupContextSelector(state => state.openUpgradePopup);

    const { folder: _folder } = useFolder({ folderId: params?.folderId, isEnabled: !!params?.folderId });
    const { course: _course, permission } = useClass({
        classId: params?.classId || _folder?.classId,
        isEnabled: !!params?.classId || !!_folder?.classId,
    });

    const folder = _folder?.userId === userId || permission === ClassPermission.ALL ? _folder : null;
    const course = permission === ClassPermission.ALL ? _course : null;

    const [isCreatingFolder, setIsCreatingFolder] = useState(false);
    const [isImportingFromQuizlet, setIsImportingFromQuizlet] = useState(false);
    const [isImportingManually, setIsImportingManually] = useState(false);
    const [isMovingFileToClass, setIsMovingFileToClass] = useState(false);
    const [isCreatingClass, setIsCreatingClass] = useState(false);
    const [isShowingMissingInputs, setIsShowingMissingInputs] = useState(false);

    const { title, desc } = getCreateContentLabels({ isTeacher });

    const toolByGroupRaw = useToolByGroup({
        setIsCreatingFolder,
        setIsImportingFromQuizlet,
        setIsImportingManually,
        setIsMovingFileToClass,
        setIsCreatingClass,
    });

    const toolByGroup = toolByGroupRaw.map((t, i) => ({
        ...t,
        isSelected: i === currToolIdx,
        onClick: () => {
            setCurrToolIdx(i);
            setFilter("");
        },
    }));

    const currTool = toolByGroup[currToolIdx];
    const currGroupTitle = currTool.title;
    const currToolList = currTool.tools;

    const currToolListFiltered = currToolList.filter(tool => tool.title.toLowerCase().includes(filter.toLowerCase()));

    // we have special case for this tab
    const isChatActivity = currGroupTitle === "Chat Activity";

    const [promptInput, updatePromptInput] = useCombinedState<{
        context: string;
        grade: { label: string; value: string | null };
        files: AssignmentFileInput[] | null;
    }>({
        context: "",
        grade: { label: "Grade", value: null },
        files: null,
    });

    const onCreateChatActivity = async () => {
        try {
            const assignmentId = uuidv4();

            if (promptInput?.grade?.value === null || promptInput.context === "") {
                setIsShowingMissingInputs(true);
                toast.error("Please fill in all the required fields");
                return;
            }

            if (hasReachedAIUsage("assignments")) {
                openUpgradePopup({
                    event: UpgradeEvent.ASSIGNMENTS,
                    context: {},
                });
                return;
            }

            onClose();

            setIsGenerating(true);

            const assignmentFields = await callCreateAssignmentFields({
                input: {
                    config: promptInput.context,
                    grade: promptInput.grade.label,
                    files: promptInput.files ?? [],
                },
            });

            await createAssignment({
                userId: userId ?? "",
                assignmentId,
                created: now().toString(),
                updated: now().toString(),
                public: false,
                classId: course?.classId ?? null,
                folderId: folder?.folderId ?? null,
                title: assignmentFields?.title,
                description: assignmentFields?.description,
                instructions: assignmentFields?.instructions,
                status: AssignmentStatus.DRAFT,
                topic: assignmentFields?.topic,
                config: promptInput.context,
                files: promptInput.files ?? [],
                type: AssignmentType.CHAT,
                settings: {
                    ...assignmentFields.settings,
                },
            });

            router.push(`/assignment/${assignmentId}/settings`);
            setIsGenerating(false);
        } catch (error) {
            // biome-ignore lint: suspicious/noConsole
            console.error(error);
            toast.error("Something went wrong, please try again.");
            setIsGenerating(false);
        }
    };

    return (
        <>
            <SidebarPopupResponsiveDialog
                isOpen={isOpen}
                onClose={onClose}
                DialogStyle={{ height: isTeacher ? "64rem" : "53rem" }}>
                {/* SIDE PART */}
                <CreateContentHeader className={br.smUpDisplayNone} title={title} desc={desc} onClose={onClose} />
                <SidebarPopupSideContainer>
                    {toolByGroup.map((item, i) => (
                        <SidebarPopupTabItem
                            id={`create-popup-tab-${item.title.toLowerCase().replace(" ", "-")}`}
                            key={item.title + i}
                            isSelected={item.isSelected}
                            onClick={item.onClick}>
                            <SidebarPopupTabItemImg
                                img={item.img}
                                alt={item.title}
                                background={item.background}
                                icon={item.icon}
                            />
                            {item.title}
                        </SidebarPopupTabItem>
                    ))}
                </SidebarPopupSideContainer>

                {/* RIGHT PART */}
                <SidebarPopupMainContainer>
                    <CreateContentHeader className={br.smDownDisplayNone} title={title} desc={desc} onClose={onClose} />

                    {currTool.type === ToolType.AI_TOOLS && (
                        <InputWithSearchbarIcon
                            value={filter}
                            onChange={e => setFilter(e.target.value)}
                            adornmentProps={{
                                size: "1.6rem",
                                color: themeColors.neutral2,
                                style: { left: "1.6rem" },
                            }}
                            placeholder="Search tools"
                            containerProps={{ className: br.smDownWidth100 }}
                            containerStyle={{
                                visibility: isChatActivity ? "hidden" : "visible",
                                marginLeft: "auto",
                                marginBottom: "1.5rem",
                            }}
                            className={br.smDownWidth100}
                            sx={{
                                fontSize: "1.2rem",
                                marginLeft: "auto",
                                width: "26rem",
                                height: "4rem",
                                backgroundColor: themeColors.background,
                                padding: "0 1.5rem 0 4rem",
                            }}
                        />
                    )}

                    {isChatActivity ? (
                        <S3UploadJobContextProvider>
                            <ChatActivityPrompt
                                promptInput={promptInput}
                                updatePromptInput={updatePromptInput}
                                containerStyle={{ overflow: "auto", margin: "0 -2rem 0 0", padding: "0 2rem 2rem 0" }}
                                isShowingMissingInputs={isShowingMissingInputs}
                            />
                        </S3UploadJobContextProvider>
                    ) : (
                        <ToolList
                            tools={currToolListFiltered}
                            folderId={folder?.folderId}
                            classId={course?.classId}
                            closePopup={onClose}
                        />
                    )}

                    {isChatActivity && (
                        <SidebarPopupBottomContainer>
                            <SidebarPopupNextBtn onClick={onCreateChatActivity} customText={"Create"} />
                        </SidebarPopupBottomContainer>
                    )}
                </SidebarPopupMainContainer>
            </SidebarPopupResponsiveDialog>
            <Mountable mount={isGenerating}>
                <LargeScreenLoading
                    isOpen={isGenerating}
                    onClose={noop}
                    title={"Kai’s setting up your assignment"}
                    body={"You can still edit all your fields to better suit your needs."}
                />
            </Mountable>

            <LazyLoaded load={isImportingFromQuizlet}>
                <ImportFromQuizletPopup
                    isOpen={isImportingFromQuizlet}
                    bypassChromeExtensionPopup={isImportingManually}
                    onClose={() => setIsImportingFromQuizlet(false)}
                    onImportClick={async ({ title, flashcards }) => {
                        if (flashcards.length === 0) {
                            return;
                        }
                        const { makeStandaloneFlashcardSet } = await import("@/hooks/flashcards/utils");
                        const { flashcardSetId } = await makeStandaloneFlashcardSet({
                            userId,
                            title,
                            flashcards,
                            quizletUrl: "MANUAL_IMPORT",
                            ...(folder
                                ? {
                                      folderId: folder.folderId,
                                      public: folder?.public,
                                      password: folder?.password,
                                      sections: folder?.sections,
                                  }
                                : {}),
                            ...(course?.classId ? { classId: course?.classId } : {}),
                        });

                        setIsImportingFromQuizlet(false);
                        onClose();
                        router.push(`/flashcards/${flashcardSetId}/edit`);
                    }}
                />
            </LazyLoaded>

            <LazyLoaded load={isMovingFileToClass}>
                <MoveFileToClassPopup
                    isOpen={isMovingFileToClass}
                    onClose={() => setIsMovingFileToClass(false)}
                    classId={course?.classId}
                    folderId={folder?.folderId}
                    sharedSections={folder?.sections}
                />
            </LazyLoaded>

            <LazyLoaded load={isCreatingFolder}>
                <NamingPopup
                    title={"New Folder"}
                    isOpen={isCreatingFolder}
                    onClose={e => {
                        e.stopPropagation();
                        setIsCreatingFolder(false);
                    }}
                    placeholder={"Untitled folder"}
                    onConfirm={async name => {
                        if (!name?.trim()?.length) {
                            throw new Error("Sorry please set a name for your folder");
                        }
                        const newFolder = await callCreateFolder({
                            userId,
                            folderId: uuidv4(),
                            name,
                            public: sharingEnabled,
                            ...(course?.classId ? { classId: course?.classId } : {}),
                            ...(folder?.folderId ? { parentId: folder?.folderId } : {}),
                        });
                        await updateFolderInFolderList({ userId, folder: newFolder });

                        setIsCreatingFolder(false);
                        router.push(`/folder/${newFolder.folderId}?isNew=true`);
                    }}
                />
            </LazyLoaded>

            <LazyLoaded load={isCreatingClass}>
                <CreateClassPopup isOpen={isCreatingClass} onClose={() => setIsCreatingClass(false)} />
            </LazyLoaded>
        </>
    );
};

export default CreateContentPopup;
