"use client";

import { STORAGE_KEYS } from "@/constants";
import { MediaUploadedData } from "@/constants/storage";
import { MediaType } from "@/graphql/schema";
import {
    resolveClassMediasSWRKey,
    resolveFolderMediasSWRKey,
    resolveMediasSWRKey,
    uploadFile,
    waitForMediaEntryCreationOnUpload,
} from "@/hooks/media/utils";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { platform } from "@/platform";
import { TIME } from "@/utils/dateTimeUtils";
import { generalizedMediaTypeFromSingleFileType } from "@/utils/fileTypeUtils";
import { cancelOngoingS3Upload } from "@/utils/s3";
import { createContext, useContextSelector } from "@/utils/use-context-selector";
import { UploadDataOutput } from "@aws-amplify/storage";
import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { mutate } from "swr";
import { v4 as uuidv4 } from "uuid";

type S3UploadJobContextValue = {
    currentUploadJob: UploadDataOutput;
    setCurrentUploadJob: (job: UploadDataOutput) => void;
    uploadProgress: number | null;
    setUploadProgress: Dispatch<SetStateAction<number | null>>;
    isUploading: boolean;
    setIsUploading: Dispatch<SetStateAction<boolean>>;
    uploadedFileId: string | null;
    confirmUpload: ({
        file,
        name,
        extension,
        userId,
        folderId,
        classId,
        isRecording,
        customBucketName,
        ignoreMediaCreation,
    }: {
        file: File | Blob | string;
        name?: string;
        extension?: string;
        userId?: string;
        folderId?: string;
        classId?: string;
        isRecording?: boolean;
        customBucketName?: string;
        ignoreMediaCreation?: boolean;
    }) => Promise<{
        cancelled: boolean;
        id: string;
        bucket: string;
        extension: string;
    }>;
    cancelUpload: () => Promise<void>;
    clearUpload: () => void;
    fileName: string | null;
    uploadRemainingTime: string | null;
    uploadedFileType: MediaType.PDF | MediaType.VIDEO | MediaType.AUDIO | null;
    fileExtension: string | null;
    currentFile: File | null;
    isFileARecording: boolean;
};

const S3UploadJobContext = createContext<S3UploadJobContextValue | null>(null);

export const S3UploadJobContextProvider = ({ children }: { children: React.ReactNode }) => {
    const { userId } = useCurrentUser();

    const [currentUploadJob, setCurrentUploadJob] = useState<UploadDataOutput>(null);
    const [uploadProgress, setUploadProgress] = useState<number | null>(null);
    const [uploadRemainingTime, setUploadRemainingTime] = useState<string | null>(null);
    const [isUploading, setIsUploading] = useState(false);

    const [fileName, setFileName] = useState<string | null>(null);
    const [fileExtension, setFileExtension] = useState<string | null>(null);

    const [currentFile, setCurrentFile] = useState<File | null>(null);
    const [isFileARecording, setIsFileARecording] = useState(false);

    const [uploadedFileId, setUploadedFileId] = useState<string | null>(null);

    const [uploadedFileType, setUploadedFileType] = useState<MediaType.PDF | MediaType.VIDEO | MediaType.AUDIO | null>(
        null
    );

    const clearUpload = useCallback(() => {
        setCurrentFile(null);
        setIsFileARecording(false);
        setCurrentUploadJob(null);
        setUploadProgress(null);
        setIsUploading(false);
        setUploadedFileId(null);
        setUploadRemainingTime(null);
        setFileName(null);
        setUploadedFileType(null);
        setFileExtension(null);
    }, []);

    const confirmUpload = useCallback(
        async ({
            file,
            name = file?.name,
            extension: _extension,
            userId: overrideUserId,
            folderId,
            classId,
            isRecording = false,
            ignoreMediaCreation = false,
            customBucketName,
        }: {
            file: File | Blob | string;
            name?: string;
            extension?: string;
            userId?: string;
            folderId?: string;
            classId?: string;
            isRecording?: boolean;
            customBucketName?: string;
            ignoreMediaCreation?: boolean;
        }) => {
            setIsUploading(true);
            setFileName(name);
            setCurrentFile(file instanceof File ? file : null);
            setIsFileARecording(isRecording);
            const lastDotIndex = name?.lastIndexOf(".");
            const extension = _extension ?? name?.substring(lastDotIndex + 1)?.toLowerCase();

            setFileExtension(extension);

            setUploadedFileType(generalizedMediaTypeFromSingleFileType(extension) || MediaType.VIDEO);

            try {
                const fetchFileBlob = await platform.fetchFileBlob();

                const fileBlob = file instanceof Blob ? file : await fetchFileBlob(file);

                const mediaId = uuidv4();
                setUploadedFileId(mediaId);

                const { id, cancelled, bucket } = await uploadFile({
                    fileBlob,
                    userId: overrideUserId ?? userId,
                    folderId,
                    classId,
                    setUploadProgress,
                    setCurrentUploadJob,
                    contentType: fileBlob.type,
                    setUploadRemainingTime,
                    mediaIdToStartWith: mediaId,
                    title: name?.split(".").slice(0, -1).join("."),
                    customBucketName,
                });

                if (cancelled) return { id, cancelled: true };

                if (!ignoreMediaCreation) {
                    if (userId) {
                        const { media } = await waitForMediaEntryCreationOnUpload({ mediaId: id });
                        if (media) {
                            const swrKeys = [
                                resolveMediasSWRKey({ userId: userId }),
                                media.classId && resolveClassMediasSWRKey({ classId: media.classId }),
                                media.folderId && resolveFolderMediasSWRKey({ folderId: media.folderId }),
                            ].filter(Boolean) as string[][];

                            await Promise.all(
                                swrKeys.map(
                                    async key =>
                                        await mutate(key, oldData => ({ ...oldData, [media.mediaId]: media }), {
                                            revalidate: false,
                                        })
                                )
                            );
                        }
                    } else {
                        const storage = await platform.storage();
                        await storage.setWithExpiry(
                            STORAGE_KEYS.MEDIA_UPLOADED_DATA,
                            { mediaId: id, extension, bucket } as MediaUploadedData,
                            TIME.HOUR
                        );
                        await storage.setWithExpiry(STORAGE_KEYS.SKIP_INTRO_POPUPS, true, TIME.MINUTE * 15);
                    }
                }

                setUploadedFileId(id);
                setIsUploading(false);
                return { id, bucket, extension, cancelled };
            } catch (e) {
                const { report } = await platform.analytics.logging();
                report(e, "media-confirmUpload", { userId, fileName, extension });
                setIsUploading(false);
                throw e;
            }
        },
        // the other deps temp because of the report function
        [clearUpload, userId]
    );

    const cancelUpload = useCallback(async () => {
        const Mixpanel = await platform.analytics.mixpanel();
        Mixpanel.track(`media upload cancelled`, {
            userId,
            uploadRemainingTime,
            uploadProgress,
            fileName,
        });
        return await cancelOngoingS3Upload({
            uploadJob: currentUploadJob,
            setUploadJob: setCurrentUploadJob,
        })
            .then(() => {
                clearUpload();
            })
            .catch(async e => {
                const { report } = await platform.analytics.logging();
                report(e, "media-cancelUpload", { currentUploadJob });
            });

        // the other deps temp because of the report
    }, [clearUpload, currentUploadJob]);

    return (
        <S3UploadJobContext.Provider
            value={{
                currentUploadJob,
                setCurrentUploadJob,
                uploadProgress,
                setUploadProgress,
                isUploading,
                setIsUploading,
                uploadedFileId,
                confirmUpload,
                cancelUpload,
                clearUpload,
                fileName,
                uploadRemainingTime,
                uploadedFileType,
                fileExtension,
                currentFile,
                isFileARecording,
            }}>
            {children}
        </S3UploadJobContext.Provider>
    );
};

export const useS3UploadJobsSelector = <T,>(selector: (value: S3UploadJobContextValue) => T): T =>
    useContextSelector(S3UploadJobContext, selector);
