import Link, { LinkWrapperProps } from "./wrappers/Link";

type ConditionalLinkProps = Omit<LinkWrapperProps, "href"> & {
    href?: string;
    condition: boolean;
};

export const ConditionalLink = ({ condition, children, ...linkedWrapperProps }: ConditionalLinkProps) => (
    <>{condition ? <Link {...linkedWrapperProps}>{children}</Link> : children}</>
);
