import { platform } from "@/platform";
import { useCallback } from "react";
import { useSWRImmutable } from "@/hooks/swr/immutable";
import { AnyFunction } from "@/types/common";

export type DynamicKeyValueStore = {
    get: (key: string) => string | null;
    getOnlyUseCookies: (key: string) => Promise<string | null>;
    find: (prefix: string) => Record<string, string>;
    set: (key: string, value: string | number, expires?: number) => void;
    remove: (key: string) => void;
    removeAll: () => void;
};

export const useLocalStorageSWR = <T>(
    key: string,
    fetcher?: AnyFunction,
    extras?: {
        default?: T;
        fallbackData?: T;
        dynamicKeyValueStore?: DynamicKeyValueStore;
        waitForFallbackData?: boolean;
    }
) => {
    const { data, mutate, isLoading } = useSWRImmutable(
        key,
        async () => {
            if (extras?.waitForFallbackData) {
                return undefined;
            }

            const storage = await platform.storage();
            const cachedStr = await storage.get(key);
            if (cachedStr !== null && cachedStr !== undefined) {
                const parsed = JSON.parse(cachedStr);
                if (parsed !== null && parsed !== undefined) {
                    return parsed as T;
                }
            }

            if (fetcher) {
                const fetched = await fetcher();
                await storage.set(key, JSON.stringify(fetched));

                return fetched as T;
            }

            if (extras?.default !== undefined && extras?.default !== null) {
                return extras.default;
            }

            return null;
        },
        {
            fallbackData: extras?.fallbackData,
        }
    );

    const set = useCallback(
        async (value: T | ((val: T) => T)) => {
            const valueToStore = typeof value === "function" ? (value as (val: T) => T)(data) : value;
            await mutate(valueToStore, { revalidate: false });

            if (extras?.dynamicKeyValueStore) {
                extras.dynamicKeyValueStore.set(key, JSON.stringify(valueToStore));
            } else {
                const storage = await platform.storage();
                await storage.set(key, JSON.stringify(valueToStore));
            }
        },
        [data, mutate, key]
    );

    const setTemporary = useCallback(
        async (value: T) => {
            mutate(value, { revalidate: false });
            // don't set to localStorage
        },
        [mutate]
    );

    return { data, set, setTemporary, isLoading, mutate };
};
