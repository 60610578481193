import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/material/styles";
import { SearchIcon } from "lucide-react";
import React from "react";

type InputWithSearchbarIconProps = {
    containerStyle?: React.CSSProperties;
    containerProps?: Omit<React.HTMLAttributes<HTMLDivElement>, "style">;
    adornmentProps?: {
        style?: React.CSSProperties;
        strokeWidth?: number;
        size?: string;
        color?: string;
    };
    noIcon?: boolean;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & { sx?: SxProps };

const InputWithSearchbarIcon = ({
    ref,
    adornmentProps,
    noIcon,
    containerStyle,
    containerProps,
    ...props
}: InputWithSearchbarIconProps & {
    ref?: React.RefObject<HTMLInputElement | null>;
}) => {
    return (
        <div style={{ position: "relative", ...containerStyle }} {...containerProps}>
            <Box
                component="input"
                ref={ref}
                {...props}
                sx={{
                    fontFamily: "var(--knowt-font-name)",
                    height: "4rem",
                    fontWeight: "600",
                    color: themeColors.neutralBlack,
                    backgroundColor: themeColors.background,
                    padding: noIcon ? "0 1.6rem 0 1.6rem" : "0 1.6rem 0 4.4rem",
                    border: "none",
                    outline: "none",
                    borderRadius: 999,
                    "&::placeholder": {
                        color: themeColors.neutral2,
                    },
                    ...props.sx,
                }}
            />
            {!noIcon && (
                <SearchIcon
                    size={adornmentProps?.size || iconSizes.MD_S}
                    color={adornmentProps?.color}
                    style={{
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        left: "1.6rem",
                        ...adornmentProps?.style,
                    }}
                    strokeWidth={adornmentProps?.strokeWidth ?? strokeWidth.normal}
                />
            )}
        </div>
    );
};

InputWithSearchbarIcon.displayName = "InputWithSearchbarIcon";

export default InputWithSearchbarIcon;
