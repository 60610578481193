import { Rem } from "@/types/common";

const RepeatAutoFillGrid = ({
    ref,
    children,
    itemMinWidth,
    style,
    ...props
}: {
    ref?: React.RefObject<HTMLDivElement>;
    children: React.ReactNode;
    itemMinWidth: Rem;
    style?: React.CSSProperties;
} & React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <div
            ref={ref}
            style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: `repeat(auto-fill, minmax(${itemMinWidth},1fr))`,
                ...style,
            }}
            {...props}>
            {children}
        </div>
    );
};

export default RepeatAutoFillGrid;
