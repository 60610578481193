import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { themeColors } from "@/utils/themeColors";

export const SidebarPopupNextBtn = ({ onClick, customText }: { onClick: () => void; customText?: string }) => {
    return (
        <CircularRectTextButton
            className="bodyBold2"
            onClick={onClick}
            sx={{
                color: themeColors.primaryDark,
                backgroundColor: themeColors.primary,
                width: "9rem",
                height: "5rem",
            }}>
            {customText ?? "Next"}
        </CircularRectTextButton>
    );
};
