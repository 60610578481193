import Image from "next/image";
import Circular from "@/components/Circular";
import { themeColors } from "@/utils/themeColors";
import { ArrowRight } from "lucide-react";
import { strokeWidth } from "@/utils/iconProps";

export const createToolIcon = ({
    background,
    img,
    icon,
}: { background: string; img: string; icon?: React.ReactNode }) => {
    return (
        <Circular radius="4rem" style={{ background }}>
            {icon || (
                <Image src={img} alt={"create flashcards"} height="20" width="20" style={{ objectFit: "contain" }} />
            )}
        </Circular>
    );
};

export const createAIToolArrowIcon = () => {
    return (
        <Circular radius="4rem" style={{ backgroundColor: themeColors.pureBlack }}>
            <ArrowRight
                size={"2.6rem"}
                color={themeColors.pureWhite}
                style={{ transform: "rotate(-45deg)" }}
                strokeWidth={strokeWidth.normal}
            />
        </Circular>
    );
};
