//TODO: SWAP IT BY TeacherAIToolCard component

import { ConditionalLink } from "@/components/ConditionalLink";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import { themeColors } from "@/utils/themeColors";
import Box from "@mui/material/Box";
import { CREATE_TOOL_ITEM } from "../utils/useToolByGroup";
import { spacing } from "@/utils/spacing";
import { createQueryStrings } from "@knowt/syncing/utils/genericUtils";

const ToolCard = ({
    tool,
    folderId,
    classId,
    closePopup,
}: { tool: CREATE_TOOL_ITEM; folderId?: string; classId?: string; closePopup: () => void }) => {
    return (
        <ConditionalLink
            id={tool.id}
            condition={!!tool?.href}
            href={tool.href + createQueryStrings({ folderId, classId })}
            onClick={closePopup}
            style={{ textDecoration: "none", height: "100%", boxSizing: "border-box" }}>
            <FlexColumn
                as={Box}
                id={tool.id}
                onClick={async () => {
                    await tool?.onClick?.();
                    closePopup(); // close popup after whatever tool is clicked
                }}
                sx={{
                    height: "100%",
                    color: themeColors.neutralBlack,
                    backgroundColor: themeColors.neutralWhite,
                    borderRadius: "2rem",
                    padding: "1.9rem 1.3rem",
                    outline: `1px solid ${themeColors.neutral1}`,
                    cursor: "pointer",
                    "&:hover": {
                        outline: `1px solid ${themeColors.neutralBlack}`,
                    },
                    boxSizing: "border-box",
                    gap: "0.5rem",
                    ...tool.sx,
                }}>
                <FlexRowAlignCenter style={{ justifyContent: "space-between", marginBottom: spacing.XS_2 }}>
                    {tool?.ToolIcon}
                </FlexRowAlignCenter>
                <span className="secondaryTextBold1" style={{ color: themeColors.neutralBlack }}>
                    {tool.title}
                </span>
                <span
                    title={tool.description}
                    className="secondaryText2 clampText"
                    style={{
                        color: themeColors.neutralBlack,
                        WebkitLineClamp: 3,
                        wordBreak: "break-word",
                        inlineSize: "95%",
                    }}>
                    {tool.description}
                </span>
            </FlexColumn>
        </ConditionalLink>
    );
};

export default ToolCard;
