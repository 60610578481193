import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { gradesMap } from "@knowt/syncing/constants/ai-tools";
import { AIFilesDnDInput, AIToolsSelect, AIToolsTextRecordArea } from "../../TeacherAITools/components/AIToolsInputs";
import { AICompletionType, AssignmentFileInput, ItemType, UpgradeEvent } from "@knowt/syncing/graphql/schema";
import toast from "react-hot-toast";
import { useStreamingAI } from "@knowt/syncing/hooks/ai/useAI";
import { useEffect, useState } from "react";
import Image from "next/image";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { assertTruthy } from "@knowt/syncing/utils/assertions";
import { useS3UploadJobsSelector } from "@knowt/syncing/context/S3UploadJobContext";
import { callAddMediaS3UserTag } from "@knowt/syncing/hooks/media/graphqlUtils";
import { SetPartialState } from "@knowt/syncing/utils/hooks/useCombinedState";
import { useUpgradePopupContextSelector } from "@/features/Payments/PaywallPopup/UpgradePopupContext";
import { AuthEvent } from "@/features/Auth/AuthPopup/utils/constants";
import { useCheckAuth } from "@/hooks/useCheckAuth";

export type ChatActivityPromptInput = {
    context: string;
    grade: {
        label: string;
        value: string | null;
    } | null;
    files: AssignmentFileInput[] | null;
};

export const CHAT_ACTIVITY_INSPIRATIONS = [
    {
        img: "/images/chat-assignment/tutor-for-students-icon.svg",
        color: "#DBF3E3",
        text: "Create a tutor for students on...",
        prompt: "I want you to act as a tutor for students on {replace with your desired topic}.\n\nYou should be knowledgeable, patient, and encouraging. Help students understand concepts clearly and provide relevant examples. Ask questions to check understanding and provide constructive feedback.",
    },
    {
        img: "/images/chat-assignment/historical-figure-icon.svg",
        color: "#DCF1FA",
        text: "Create a chat with a historical figure on...",
        prompt: "I want you to take on the role of {replace with a historical figure}.\n\nRespond as they would based on their known personality, beliefs, and the time period they lived in. Share their experiences, perspectives, and knowledge while staying true to historical accuracy. ",
    },
    {
        img: "/images/chat-assignment/debate-icon.svg",
        color: "#FFE8E8",
        text: "Set up a debate on...",
        prompt: "I want you to engage in a thoughtful debate with a student on {replace with desired topic}.\n\nPresent logical arguments, consider different perspectives, use evidence to support your points, and maintain a respectful tone. Challenge their ideas constructively while being open to counter-arguments.",
    },
    {
        img: "/images/chat-assignment/literary-character-icon.svg",
        color: "#DBE3FF",
        text: "Create a chat with a literary character on...",
        prompt: "I want you to embody {replace with a literary character} from the novel {replace with novel name} and engage in a thought provoking discussion with my students.\n\nRespond in their voice, reflecting their personality, background, and experiences from their story. Express their thoughts and feelings as they would, staying consistent with their character development.",
    },
];

export const ActivityInspirationPill = ({
    inspiration,
    onClick,
}: { inspiration: { img: string; text: string; color: string }; onClick: () => void }) => (
    <CircularRectTextButton
        style={{ gap: spacing.XS, padding: "1rem", border: `1px solid ${themeColors.neutral1}` }}
        onClick={onClick}>
        <FlexColumnAlignJustifyCenter
            style={{ backgroundColor: inspiration.color, padding: "0.3rem", borderRadius: "0.5rem" }}>
            <Image src={inspiration.img} alt={inspiration.text} width={14} height={14} />
        </FlexColumnAlignJustifyCenter>
        <span className="secondaryText2">{inspiration.text}</span>
    </CircularRectTextButton>
);

const ChatActivityPrompt = ({
    promptInput,
    updatePromptInput,
    revisionMode,
    containerStyle,
    hideInstructions,
    isShowingMissingInputs,
}: {
    promptInput: ChatActivityPromptInput;
    updatePromptInput: SetPartialState<{
        context: string;
        grade: {
            label: string;
            value: string | null;
        };
        files: AssignmentFileInput[] | null;
    }>;
    containerStyle?: React.CSSProperties;
    hideInstructions?: boolean;
    revisionMode?: boolean;
    isShowingMissingInputs: boolean;
}) => {
    const { user, hasReachedAIUsage } = useCurrentUser();
    const openUpgradePopup = useUpgradePopupContextSelector(s => s.openUpgradePopup);
    const { checkAuth } = useCheckAuth();

    const [textAreaKey, setTextAreaKey] = useState(0);
    const [droppedFile, setDroppedFile] = useState<File | null>(null);
    const [isUploadingFile, setIsUploadingFile] = useState(false);

    const confirmUpload = useS3UploadJobsSelector(state => state.confirmUpload);
    const clearUpload = useS3UploadJobsSelector(state => state.clearUpload);
    const uploadProgress = useS3UploadJobsSelector(state => state.uploadProgress);

    const { callAI } = useStreamingAI({
        itemId: "",
        itemType: ItemType.ASSIGNMENT,
        type: AICompletionType.TEACHER_TOOL_FIELDS_HELPER,
    });

    const handleHelpFillField = async (description: string) => {
        try {
            const { response } = await callAI({
                aiParams: {
                    tool: AICompletionType.ASSIGNMENT_CHAT,
                    description: description,
                    fields: [],
                },
            });

            const responseObject = JSON.parse(response);

            return responseObject.topic;
        } catch (e) {
            if (e.message === "Over TEACHER_TOOL_FIELDS_HELPER usage limit") {
                return toast.error("You have exceeded the usage limit for the Teacher Tool Helper feature.");
            }
            toast.error("Something went wrong please try again.");
        }
    };

    const handleUploadFile = async (data: { name?: string; file: File; extension?: string }) => {
        if (!user) {
            return;
        }

        try {
            assertTruthy(data.file, "file should be defined");

            if (uploadProgress) clearUpload();

            if (hasReachedAIUsage("videos") || hasReachedAIUsage("pdfs")) {
                return openUpgradePopup({
                    event: UpgradeEvent.MAX_PDF_AI,
                    context: {},
                });
            }

            const { id, extension, bucket, cancelled } = await confirmUpload({
                file: data.file,
            });
            await callAddMediaS3UserTag({
                mediaId: `${id}.${extension}`,
                bucket,
                startProcessing: true,
            });

            updatePromptInput({
                files: [...(promptInput.files ?? []), { itemId: id, itemType: ItemType.MEDIA }],
            });

            if (cancelled) {
                toast.error("Upload cancelled");
                return;
            }
        } catch {
            toast.error("Upload failed, please try again after reloading the page.");
        } finally {
            setIsUploadingFile(false);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies:
    useEffect(() => {
        if (droppedFile) {
            checkAuth({
                event: AuthEvent.AI_TOOLS,
                onAuthSuccess: () => {
                    setIsUploadingFile(true);
                    handleUploadFile({
                        name: droppedFile.name,
                        file: droppedFile,
                    });
                },
            });
        }
    }, [droppedFile]);

    return (
        <FlexColumn
            className="scrollbar-thin"
            style={{ overflow: "auto", paddingBottom: "2rem", gap: spacing.MD, ...containerStyle }}>
            <FlexColumn style={{ gap: spacing.SM }}>
                <FlexColumn>
                    {!hideInstructions && (
                        <span className="secondaryTextBold1">Need some inspiration? Try any one of these!</span>
                    )}
                </FlexColumn>
                {!hideInstructions && (
                    <FlexRowAlignCenter style={{ flexWrap: "wrap", gap: "1rem" }}>
                        {CHAT_ACTIVITY_INSPIRATIONS.map((inspiration, index) => (
                            <ActivityInspirationPill
                                key={`${inspiration}_${index}`}
                                inspiration={inspiration}
                                onClick={() => {
                                    updatePromptInput({
                                        context: inspiration.prompt,
                                    });
                                    // this is needed to force the text area to update
                                    setTextAreaKey(prev => prev + 1);
                                }}
                            />
                        ))}
                    </FlexRowAlignCenter>
                )}
                <FlexColumn style={{ gap: spacing.XXS }}>
                    <FlexRowAlignCenter style={{ gap: spacing.XS }}>
                        <span className="secondaryTextBold1">
                            {revisionMode ? "How can we make this better?" : "Topic, standard, or objective"}
                        </span>
                        <span className="bodyBold1" style={{ color: themeColors.errorPrimary }}>
                            *
                        </span>
                    </FlexRowAlignCenter>
                    <AIToolsTextRecordArea
                        key={textAreaKey}
                        placeholder={
                            "In a chat activity, students can engage with AI to be quizzed on a topic, get extra help, or practice any skill — from solving math problems or ordering items in a restaurant in french to practice a langue. Just start typing how you want the activity to work or use our starter prompts above!"
                        }
                        handWrittenValue={promptInput.context}
                        onTextChange={text => updatePromptInput({ context: text })}
                        isMandatory
                        textAreaStyle={{
                            minHeight: "2rem",
                            height: "fit-content",
                            fontSize: "1.4rem",
                        }}
                        handleHelpFillField={desc => handleHelpFillField(desc)}
                        generationOptionsStyle={{
                            right: 0,
                            bottom: "-12rem",
                        }}
                        isShowingMissingInputs={isShowingMissingInputs}
                    />
                </FlexColumn>
            </FlexColumn>
            {!revisionMode && (
                <>
                    <AIToolsSelect
                        label={"What grade level?"}
                        labelClassName="secondaryTextBold1"
                        options={gradesMap}
                        selected={promptInput.grade ?? { label: "Grade", value: null }}
                        onChange={value => updatePromptInput({ grade: value })}
                        isHorizontalLayout={true}
                        isMandatory={true}
                        isShowingMissingInputs={isShowingMissingInputs}
                    />

                    <AIFilesDnDInput
                        label={"Add a file for more context"}
                        labelClassName="secondaryTextBold1"
                        droppedFile={droppedFile}
                        setDroppedFile={setDroppedFile}
                        isUploading={isUploadingFile}
                        stopUpload={() => {
                            setDroppedFile(null);
                            setIsUploadingFile(false);
                        }}
                    />
                </>
            )}
        </FlexColumn>
    );
};

export default ChatActivityPrompt;
