"use client";

import Box from "@mui/material/Box";
import { SxProps } from "@mui/system";
import { spacing } from "@/utils/spacing";
import Select, { SelectProps } from "../Select";
import { themeColors } from "@/utils/themeColors";
import { borderRadius } from "@/utils/borderRadius";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import ResponsiveDialog from "@/features/ResponsiveDialog/ResponsiveDialog";
import DialogCloseBtn from "@/features/ResponsiveDialog/styled/DialogCloseBtn";
import useClassSettings from "@/features/ClassSettingsPage/hooks/useClassSettings";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";

const NUMBER_SECTIONS_OPTIONS = Array.from({ length: 15 }, (_, index) => ({
    label: (index + 1).toString(),
    value: index + 1,
}));

type ClassSettingsCardProps = {
    isOpen: boolean;
    onClose: () => void;
    moveFileAfterClassCreation?: (classId: string) => void;
    noRouting?: boolean;
};

const ClassInput = ({ ...props }) => {
    return (
        <Box
            component={"input"}
            sx={{
                fontFamily: "var(--knowt-font-name)",
                height: "5rem",
                fontWeight: "600",
                color: "var(--color-neutral-black)",
                backgroundColor: themeColors.background,
                padding: "1.6rem",
                border: "none",
                outline: "none",
                borderRadius: borderRadius.card,
            }}
            {...props}
        />
    );
};

const SectionsNumberSelect = ({
    options,
    onChange,
    selected,
    placeholder,
    btnStyle,
    menuSx,
    ...props
}: SelectProps<{ label: string; value: number }> & {
    placeholder: string;
    btnStyle?: React.CSSProperties;
    menuSx?: SxProps;
}) => {
    const noSelectedOption = selected === null || selected === undefined;

    return (
        <Select
            options={options}
            onChange={onChange}
            menuSx={{ width: "27rem", maxHeight: "50vh", ...menuSx }}
            btnSx={{
                width: "26rem",
                border: `1px solid ${themeColors.neutral1}`,
                padding: spacing.SM,
                borderRadius: borderRadius.card,
                justifyContent: "space-between",
                color: noSelectedOption ? themeColors.neutral2 : themeColors.neutralBlack,
                ...btnStyle,
            }}
            renderLabel={selected => {
                if (!selected?.value) {
                    return placeholder;
                }
                return selected?.label;
            }}
            selected={selected}
            transitionOrigin={{
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
                transformOrigin: { vertical: "top", horizontal: "right" },
            }}
            {...props}
        />
    );
};

const CreateClassPopup = ({
    isOpen,
    onClose,
    noRouting = false,
    moveFileAfterClassCreation,
}: ClassSettingsCardProps) => {
    const { classSettings, updateClassSettings, updateNumberOfSections, handleSectionNameChange, handleCreateClass } =
        useClassSettings(undefined);

    const handleOnCreate = async () => {
        const newClassId = await handleCreateClass(noRouting);

        if (newClassId && moveFileAfterClassCreation) {
            moveFileAfterClassCreation(newClassId);
        }

        onClose();
    };

    const renderContent = () => {
        return (
            <FlexColumn
                style={{
                    backgroundColor: themeColors.neutralWhite,
                    borderRadius: borderRadius.card,
                    gap: "3rem",
                }}>
                <FlexRowAlignCenter style={{ justifyContent: "space-between" }}>
                    <FlexColumn style={{ gap: spacing.XXS }}>
                        <h1 className="heading5">Create a new class</h1>

                        <span className="secondaryText1">
                            Invite students to your class to share files, assign work, and track progress
                        </span>
                    </FlexColumn>
                    <DialogCloseBtn onClick={onClose} />
                </FlexRowAlignCenter>

                <FlexColumn style={{ gap: spacing.MD }}>
                    <FlexColumn style={{ gap: spacing.XS }} id="class-info-form">
                        <span className="secondaryTextBold1">{"Set a class name"}</span>
                        <ClassInput
                            placeholder="Enter class name"
                            value={classSettings.name}
                            onChange={input => {
                                updateClassSettings({ name: input.target.value });
                            }}
                        />
                    </FlexColumn>

                    <FlexColumn style={{ gap: spacing.XS }}>
                        <span className="secondaryTextBold1">Enter a short class description (optional)</span>
                        <ClassInput
                            placeholder="Class description"
                            value={classSettings.description}
                            onChange={input => {
                                const desc = input.target.value;
                                if (desc.length > 70) return;
                                updateClassSettings({ description: desc });
                            }}
                        />
                    </FlexColumn>

                    <FlexRowAlignCenter style={{ justifyContent: "space-between" }} id="create-class-add-sections">
                        <span className="secondaryTextBold1">How many sections/periods do you teach?</span>

                        <SectionsNumberSelect
                            options={NUMBER_SECTIONS_OPTIONS}
                            onChange={({ value }) => updateNumberOfSections(value)}
                            selected={
                                NUMBER_SECTIONS_OPTIONS.find(({ value }) => value === classSettings.sections?.length) ||
                                NUMBER_SECTIONS_OPTIONS[0]
                            }
                            placeholder={"Select number of sections"}
                        />
                    </FlexRowAlignCenter>

                    <FlexColumn style={{ gap: spacing.SM }}>
                        {classSettings.sections?.length > 0
                            ? classSettings.sections?.map((section, index) => (
                                  <ClassInput
                                      key={index}
                                      value={section?.name}
                                      onChange={newName => handleSectionNameChange(index, newName.target.value)}
                                  />
                              ))
                            : null}
                    </FlexColumn>
                    <CircularRectTextButton
                        id="create-class-popup-create-button"
                        disabled={!classSettings.name}
                        className="secondaryTextBold1"
                        onClick={handleOnCreate}
                        style={{
                            marginLeft: "auto",
                            width: "fit-content",
                            color: !classSettings.name ? themeColors.neutral2 : themeColors.primaryDark,
                            padding: `${spacing.XS} ${spacing.SM}`,
                            backgroundColor: !classSettings.name ? themeColors.neutral1 : themeColors.primary,
                        }}>
                        Create class
                    </CircularRectTextButton>
                </FlexColumn>
            </FlexColumn>
        );
    };

    return (
        <ResponsiveDialog
            isOpen={isOpen}
            onClose={onClose}
            DialogStyle={{
                width: "100%",
                maxWidth: "72.6rem",
                borderRadius: borderRadius.largeModal,
                transition: "height 0.35s ease",
                overflowY: "auto",
                padding: "3rem",
            }}>
            {renderContent()}
        </ResponsiveDialog>
    );
};

export default CreateClassPopup;
