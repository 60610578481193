import {
    callGetFolder,
    callListAllAccessibleFolders,
    callListFoldersByClass,
    callListFoldersByParent,
    callListFoldersByUser,
    callUpdateFolder,
} from "@/hooks/folders/graphqlUtils";
import {
    resolveAccessibleFoldersSWRKey,
    resolveClassFoldersSWRKey,
    resolveFolderNestedItemsSWRKey,
    resolveFolderSWRKey,
    resolveFoldersSWRKey,
    resolveNestedFoldersSWRKey,
} from "@/hooks/folders/utils";
import { useSWRImmutable } from "@/hooks/swr/immutable";
import { platform } from "@/platform";
import { filterClassItems } from "@/utils/course";
import { fromEntries } from "@/utils/genericUtils";
import { FlashcardSet, Folder, ItemType, Note } from "@knowt/syncing/graphql/schema";
import { useCallback, useMemo } from "react";
import { callListFlashcardSetsByFolder } from "../flashcards/graphqlUtils";
import { callListNotesByFolder } from "../notes/graphqlUtils";
import { useCurrentUser } from "../user/useCurrentUser";

export const useAllFolders = ({
    otherUserId = null,
    isEnabled = true,
    includeTrash = true,
    fallbackData = undefined,
}: {
    otherUserId?: string | null;
    isEnabled?: boolean;
    includeTrash?: boolean;
    fallbackData?: Record<string, Folder>;
} = {}) => {
    const { userId: currentUserId } = useCurrentUser();
    const userId = otherUserId || currentUserId;

    const { data, error, mutate } = useSWRImmutable(
        resolveFoldersSWRKey({ userId, isEnabled }),
        () => callListFoldersByUser({ userId }),
        { fallbackData }
    );

    const allFolders = useMemo(() => {
        if (!data) return null;
        return fromEntries(Object.entries(data).filter(([, folder]) => includeTrash || !!folder.trash === false));
    }, [data, includeTrash]);

    return { allFolders, isLoading: !allFolders && !error, mutate };
};

export const useFolders = ({
    isEnabled = true,
    parentId = null,
    inTrash,
    fallbackData,
    showNestedFolders = false,
}: {
    isEnabled?: boolean;
    parentId?: string | null;
    inTrash?: boolean;
    fallbackData?: Record<string, Folder>;
    showNestedFolders?: boolean;
}) => {
    const { allFolders, isLoading, mutate } = useAllFolders({ isEnabled, fallbackData });

    const filteredFolders: Record<string, Folder> = useMemo(() => {
        if (!allFolders) return null;

        return fromEntries(
            Object.entries(allFolders)
                .filter(([, folder]) => !!folder.trash === inTrash)
                .filter(([, folder]) => folder.parentId === parentId || showNestedFolders)
        );
    }, [allFolders, parentId, inTrash, showNestedFolders]);

    return { folders: filteredFolders, isLoading, mutate };
};

export const useFolder = ({
    folderId,
    isEnabled = true,
    fallbackData = undefined,
}: {
    folderId: string | null | undefined;
    isEnabled?: boolean;
    fallbackData?: Folder | undefined;
}) => {
    const { userId } = useCurrentUser();

    const { data: folder, mutate } = useSWRImmutable(
        resolveFolderSWRKey({
            folderId,
            isEnabled,
        }),
        async () => {
            const storage = await platform.storage();
            const password = (await storage.getWithExpiry(`${ItemType.FOLDER}-${folderId}-pwd`)) as string;

            return await callGetFolder({ folderId, password });
        },
        { fallbackData }
    );

    const update = useCallback(
        async (newFolderData: Partial<Folder>) => {
            if (!userId || !folder || !folderId) {
                return;
            }

            const updatedFields = { ...newFolderData, userId, folderId };

            //update cache
            await mutate(
                oldFolder => {
                    return { ...oldFolder, ...updatedFields };
                },
                { revalidate: false }
            );

            //update backend
            await callUpdateFolder(folderId, updatedFields).catch(async () => {
                await mutate(
                    oldFolder => {
                        return { ...oldFolder, ...folder };
                    },
                    { revalidate: true }
                );
            });
        },
        [userId, folder, folderId, mutate]
    );

    return { folder, update, mutate, readOnly: folder?.userId !== userId };
};

export const useFolderAncestors = ({
    folderId,
    otherUserId = null,
    includeAllClassesFolders = false,
}: {
    folderId: string | null | undefined;
    otherUserId?: string;
    includeAllClassesFolders?: boolean;
}) => {
    const { allFolders: allOwnedFolders = {}, mutate } = useAllFolders({ otherUserId });
    const { allAccessibleFolders = {} } = useAllAccessiblesFolders({ isEnabled: includeAllClassesFolders });

    const allDisplayedFolders = useMemo(() => {
        return includeAllClassesFolders ? { ...allOwnedFolders, ...allAccessibleFolders } : allOwnedFolders;
    }, [allOwnedFolders, allAccessibleFolders, includeAllClassesFolders]);

    const ancestors = useMemo(() => {
        if (!allDisplayedFolders) return null;

        const ancestors: Folder[] = [];
        let currentFolder = allDisplayedFolders[folderId];

        while (currentFolder) {
            ancestors.push(currentFolder);
            currentFolder = allDisplayedFolders[currentFolder.parentId];
        }

        return ancestors.reverse();
    }, [allDisplayedFolders, folderId]);

    return { ancestors, mutate };
};

export const useNestedFolders = ({
    parentId,
    inTrash = false,
    isEnabled = true,
    fallbackData,
    password,
}: {
    parentId: string;
    inTrash?: boolean;
    isEnabled?: boolean;
    fallbackData?: Record<string, Folder>;
    password?: string;
}) => {
    const { data, error, mutate } = useSWRImmutable(
        resolveNestedFoldersSWRKey({ parentId }),
        () => callListFoldersByParent({ parentId, password }),
        { fallbackData }
    );

    const folders = useMemo(() => {
        if (!data) return undefined;
        return fromEntries(
            Object.entries(data).filter(([, folder]) => !!folder.trash === inTrash && folder.parentId === parentId)
        );
    }, [data, parentId, inTrash]);

    return { folders, isLoading: isEnabled && !folders && !error, mutate };
};

export const useClassFolders = ({
    classId,
    sectionId,
    inTrash = false,
    isEnabled = true,
    fallbackData,
}: {
    classId: string | null | undefined;
    sectionId?: string | null | undefined;
    inTrash?: boolean;
    isEnabled?: boolean;
    fallbackData?: Record<string, Folder>;
}) => {
    const { user } = useCurrentUser();

    const { data, error, mutate } = useSWRImmutable(
        resolveClassFoldersSWRKey({ classId, isEnabled }),
        () => callListFoldersByClass({ classId }),
        { fallbackData }
    );

    const classFolders = useMemo(() => {
        if (!classId || !data) return {};
        return fromEntries(filterClassItems({ user, classId, items: data, inTrash, sectionId }));
    }, [data, inTrash, classId, sectionId, user]);

    return { classFolders, isLoading: !classFolders && !error, mutate };
};

export const useAllAccessiblesFolders = ({
    isEnabled = true,
    fallbackData,
}: {
    isEnabled?: boolean;
    fallbackData?: Record<string, Folder>;
}) => {
    const { data, error, mutate } = useSWRImmutable(
        resolveAccessibleFoldersSWRKey({ isEnabled }),
        () => callListAllAccessibleFolders(),
        { fallbackData }
    );

    const allAccessibleFolders = useMemo(() => {
        if (!data) return {};
        return fromEntries(Object.entries(data).filter(([, folder]) => !!folder.trash === false));
    }, [data]);

    return { allAccessibleFolders, isLoading: !allAccessibleFolders && !error, mutate };
};

export const useFolderNestedItems = ({
    folderId,
    isEnabled = true,
    fallbackData,
}: {
    folderId: string;
    isEnabled?: boolean;
    fallbackData?: {
        folders: any[] | Record<string, Folder>;
        notes: any[] | Record<string, Note>;
        flashcardSets: any[] | Record<string, FlashcardSet>;
    };
}) => {
    const { data, error, mutate } = useSWRImmutable(
        resolveFolderNestedItemsSWRKey({ folderId, isEnabled }),
        async () => {
            const [folders, notes, flashcardSets] = await Promise.all([
                callListFoldersByParent({ parentId: folderId }),
                callListNotesByFolder({ folderId }),
                callListFlashcardSetsByFolder({ folderId }),
            ]);

            return {
                folders: folders || [],
                notes: notes || [],
                flashcardSets: flashcardSets || [],
            };
        },
        { fallbackData }
    );

    const nestedItems = useMemo(() => {
        if (!data) return undefined;
        return fromEntries(Object.entries(data));
    }, [data]);

    return {
        nestedItems,
        isLoading: !nestedItems && !error,
        mutate,
    };
};
