import { SetState } from "@knowt/syncing/types/common";
import { fileTypeFromMimeType } from "@knowt/syncing/utils/fileTypeUtils";
import { convertBlobToNote } from "@knowt/syncing/utils/importFile";
import loadScript from "load-script";
import { getImgDimensions } from "../fileUtils";
import { log } from "@/utils/analytics/logging";
import { clientId, GOOGLE_API_KEY, GOOGLE_SDK_URL, DRIVE_SCOPE } from "@/config/deployConstants";

declare global {
    interface Window {
        gapi: any;
        google: any;
    }
}

class Document {
    id: string;
    mimeType: string;
    name: string;

    constructor(props: { id: string; mimeType: string; name: string; description: string; url: string }) {
        this.id = props.id;
        this.mimeType = props.mimeType;
        this.name = props.name;
    }
}

class Picker {
    private document: Document;

    pickerApiLoaded: boolean;
    scriptLoadingStarted: boolean;
    oauthToken: boolean;

    constructor(
        private importNewNote: ({ content, title }) => Promise<void>,
        private importOtherFiles: ({ blob, title }) => Promise<void>,
        private setImporting: SetState<boolean>,
        private importTypes: DriveImportType = DriveImportType.ALL
    ) {
        this.pickerApiLoaded = false;
        this.scriptLoadingStarted = false;
        this.oauthToken = false;
    }

    isGoogleReady = () => !!window.gapi;

    /**
     * Begins the importing process
     */
    init = () => {
        if (this.isGoogleReady()) {
            this.onApiLoad();
        } else if (!this.scriptLoadingStarted) {
            this.scriptLoadingStarted = true;
            loadScript(GOOGLE_SDK_URL, this.onApiLoad);
        }
    };

    onApiLoad = () => {
        window.gapi.load("auth", { callback: this.onAuthApiLoad });
        window.gapi.load("picker", { callback: this.onPickerApiLoad });
    };

    onAuthApiLoad = () => {
        window.gapi.auth.authorize(
            {
                client_id: clientId,
                scope: DRIVE_SCOPE,
                immediate: false,
            },
            this.handleAuthResult
        );
    };

    onPickerApiLoad = () => {
        this.pickerApiLoaded = true;
        this.createPicker();
    };

    handleAuthResult = (authResult: any) => {
        if (authResult && !authResult.error) {
            this.oauthToken = authResult.access_token;
            this.createPicker();
        }
    };

    createPicker = () => {
        if (!this.pickerApiLoaded || !this.oauthToken) {
            return;
        }

        const docs =
            "application/vnd.google-apps.document," +
            "application/msword," +
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

        const rest_text =
            "application/vnd.google-apps.presentation," +
            "application/pdf," +
            "application/vnd.openxmlformats-officedocument.presentationml.presentation" +
            "application/vnd.ms-powerpoint," +
            "application/vnd.google-apps.spreadsheet," +
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,";

        const videos =
            "video/mp4," +
            "audio/mp4," +
            "video/quicktime," +
            "video/x-matroska," +
            "video/x-msvideo," +
            "video/x-ms-wmv," +
            "audio/mpeg," +
            "audio/wav," +
            "audio/ogg";

        const medias =
            "application/pdf," +
            "application/vnd.google-apps.presentation," +
            "application/vnd.openxmlformats-officedocument.presentationml.presentation" +
            "application/vnd.ms-powerpoint," +
            "," +
            videos;

        let mimeTypes = "";

        switch (this.importTypes) {
            case DriveImportType.ALL:
                mimeTypes = docs + "," + rest_text + "," + videos;
                break;
            case DriveImportType.DOCS:
                mimeTypes = docs;
                break;
            case DriveImportType.ALL_TEXT:
                mimeTypes = docs + "," + rest_text;
                break;
            case DriveImportType.MEDIAS:
                mimeTypes = medias;
                break;
        }

        const view: any = new window.google.picker.View(window.google.picker.ViewId.DOCS);
        view.setMimeTypes(mimeTypes);

        const picker: any = new window.google.picker.PickerBuilder()
            .addView(view)
            .setOAuthToken(this.oauthToken)
            .setDeveloperKey(GOOGLE_API_KEY)
            .setCallback(this.pickerCallback)
            .build();

        picker.setVisible(true);
    };

    pickerCallback = (data: { action: any; docs: any[] }) => {
        return new Promise<void>((resolve: () => void) => {
            if (data.action === window.google.picker.Action.PICKED) {
                this.document = new Document(data.docs[0]);
                this.downloadFile();
                resolve();
            } else {
                this.setImporting(false);
            }
        });
    };

    getAccessToken = () => {
        return window.gapi.auth.getToken().access_token;
    };

    downloadFile = async () => {
        this.setImporting(true);

        switch (this.document.mimeType) {
            // Files that can be exported and converted on our backend
            case "application/pdf":
            case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            case "application/msword":
            case "application/vnd.ms-powerpoint":
            case "video/mp4":
            case "audio/mp4":
            case "video/quicktime":
            case "video/x-matroska":
            case "video/x-msvideo":
            case "video/x-ms-wmv":
            case "audio/mpeg":
            case "audio/wav":
            case "audio/ogg":
                return await this.importFileFromDriveUrl(
                    `https://www.googleapis.com/drive/v3/files/${this.document.id}/?alt=media`,
                    null
                );

            // files that need to be exported to standard formats and then converted by the backend
            case "application/vnd.google-apps.presentation":
                return await this.importFileFromDriveUrl(
                    `https://www.googleapis.com/drive/v3/files/${this.document.id}/export/?alt=media&mimeType=application/vnd.openxmlformats-officedocument.presentationml.presentation`,
                    null
                );

            // Files that can be exported to html directly by drive
            case "application/vnd.google-apps.document":
                // standard drive export format nests lists and includes in styling
                // because of that we export as regular drive docs as MS Word docs
                return await this.importFileFromDriveUrl(
                    `https://www.googleapis.com/drive/v3/files/${this.document.id}/export?mimeType=application/vnd.openxmlformats-officedocument.wordprocessingml.document&fields=*`,
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                );

            case "application/vnd.google-apps.spreadsheet":
                return await this.importFileFromDriveUrl(
                    `https://www.googleapis.com/drive/v3/files/${this.document.id}/export?mimeType=application/vnd.openxmlformats-officedocument.spreadsheetml.sheet&fields=*`,
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                );
        }
    };

    importFileFromDriveUrl = async (driveUrl: string, customMimeType: string | null) => {
        let blob: Blob;
        try {
            blob = await fetch(driveUrl, {
                headers: new Headers({ Authorization: "Bearer " + this.getAccessToken() }),
            }).then(resp => resp.blob());
            if (blob.type === "application/json") {
                // if it returns a json, it means the file was too large to export. we can use the alternate method
                throw new Error("Invalid file type");
            }
        } catch {
            // getting the file metadata contains exportLinks, we can use that, and theres no size limit
            const fileInfo = await fetch(
                `https://www.googleapis.com/drive/v3/files/${this.document.id}?fields=exportLinks`,
                {
                    headers: new Headers({ Authorization: "Bearer " + this.getAccessToken() }),
                }
            ).then(resp => resp.json());

            const linkToGet = {
                "application/vnd.google-apps.presentation":
                    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                "application/vnd.google-apps.document":
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/vnd.google-apps.spreadsheet":
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            }[this.document.mimeType];

            blob = await fetch(fileInfo.exportLinks[linkToGet], {
                headers: new Headers({ Authorization: "Bearer " + this.getAccessToken() }),
            }).then(resp => resp.blob());
        }

        try {
            const { content } = await convertBlobToNote({
                blob: blob,
                fileType: fileTypeFromMimeType(customMimeType ?? this.document.mimeType),
                getImgDimensions,
            });

            this.importFinishedCallback({
                content,
                title: this.document.name || "Imported Note",
            });
        } catch (error) {
            // we cant import to a note, lets use the other callback
            try {
                this.setImporting(false);
                this.importOtherFiles({ blob, title: this.document.name });
                log(error);
            } catch {
                this.importFinishedCallback({ content: null, title: null });
            }
        }
    };

    importFinishedCallback = ({ content, title }: { content: string; title: string }) => {
        this.setImporting(false);
        this.importNewNote({ content, title });
    };
}

export enum DriveImportType {
    ALL = "ALL",
    DOCS = "DOCS",
    ALL_TEXT = "ALL_TEXT",
    MEDIAS = "MEDIAS",
}

const importFromDrive = (
    importNewNote: ({ content, title }) => Promise<void>,
    importOtherFiles: ({ blob, title }) => Promise<any>,
    setImporting: SetState<boolean>,
    importTypes: DriveImportType = DriveImportType.ALL
) => {
    new Picker(importNewNote, importOtherFiles, setImporting, importTypes).init();
};

export default importFromDrive;
