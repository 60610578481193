import { spacing } from "@/utils/spacing";
import ClickPlayLottie from "./ClickPlayLottie";
import { FlexColumnAlignJustifyCenter } from "./Flex";
import kaiSpinBike from "@/public/images/lottie/kai-spin-bike.json";
import ResponsiveDialog from "@/features/ResponsiveDialog/ResponsiveDialog";
import { useAutoSave } from "@/hooks/useAutoSave";
import noop from "@knowt/syncing/utils/noop";

const LargeScreenLoading = ({
    isOpen,
    onClose,
    title = "Kai just needs a moment",
    body = "Everything you wanted and more will be ready in just a few seconds.",
}: { isOpen: boolean; onClose?: () => void; title?: string; body?: string }) => {
    // fake autosave to prevent the tab/window from closing
    useAutoSave({
        isDirty: () => true,
        save: async () => noop(),
        block: true,
        interval: 15_000,
    });

    return (
        <ResponsiveDialog
            isOpen={isOpen}
            onClose={onClose}
            disableEscapeKeyDown
            DialogStyle={{
                borderRadius: "2rem",
                width: "80%",
                maxWidth: "85rem",
                height: "70%",
            }}
            SlideMenuStyle={{ padding: "3rem 2rem" }}>
            <FlexColumnAlignJustifyCenter style={{ height: "100%", gap: spacing.MD }}>
                <div
                    style={{
                        width: "30rem",
                        height: "25rem",
                    }}>
                    <ClickPlayLottie
                        loop
                        style={{
                            width: "30rem",
                            height: "25rem",
                        }}
                        animationData={kaiSpinBike}
                    />
                </div>
                <FlexColumnAlignJustifyCenter style={{ gap: spacing.XS, textAlign: "center" }}>
                    <span className="bodyXl" style={{ fontWeight: "bold" }}>
                        {title}
                    </span>
                    <span className="body1">{body}</span>
                </FlexColumnAlignJustifyCenter>
            </FlexColumnAlignJustifyCenter>
        </ResponsiveDialog>
    );
};

export default LargeScreenLoading;
