import React from "react";
import { LucideIcon } from "lucide-react";
import CircularRectTextButton, { CircularRectTextButtonProps } from "./CircularRectTextButton";
import { themeColors } from "@/utils/themeColors";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import Circular from "@/components/Circular/Circular";

interface FilledIconTextButtonProps extends CircularRectTextButtonProps {
    Icon: LucideIcon;
    IconProps?: React.ComponentProps<LucideIcon>;
    CircularProps?: React.ComponentProps<typeof Circular>;
    primaryColor?: string;
    secondaryColor: string;
    primaryBg?: string;
    secondaryBg: string;
    iconColor?: string;
    iconHoverColor?: string;
}

export const FilledIconTextButton: React.FC<FilledIconTextButtonProps> = ({
    Icon,
    IconProps,
    CircularProps,
    primaryBg = themeColors.neutralWhite,
    primaryColor = themeColors.neutralBlack,
    secondaryBg,
    secondaryColor,
    iconColor,
    iconHoverColor,
    children,
    sx,
    ...btnProps
}) => {
    return (
        <CircularRectTextButton
            sx={{
                backgroundColor: primaryBg,
                color: primaryColor,
                fontSize: "1.4rem",
                padding: "1rem 1.5rem 1rem 0.8rem",
                width: "auto",
                height: "5rem",
                "&:active": { transform: "scale(0.99)" },
                gap: "0.5rem",
                "& svg": {
                    color: iconColor || primaryColor,
                },
                "&:hover": {
                    backgroundColor: secondaryBg,
                    color: secondaryColor,
                    "& svg": {
                        color: iconHoverColor || secondaryColor,
                    },
                },
                ...sx,
            }}
            {...btnProps}>
            <Circular
                {...CircularProps}
                style={{
                    ...CircularProps?.style,
                }}
                radius="3.4rem">
                <Icon size={iconSizes.SM} strokeWidth={strokeWidth.bold} {...IconProps} />
            </Circular>
            {children}
        </CircularRectTextButton>
    );
};

export default FilledIconTextButton;
