"use client";
import { Class, ClassSection } from "@knowt/syncing/graphql/schema";
import { COVER_COLORS, SECTIONS_COLORS } from "@knowt/syncing/hooks/classes/constants";
import { createClass, updateClass } from "@knowt/syncing/hooks/classes/utils";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import useCombinedState from "@knowt/syncing/utils/hooks/useCombinedState";
import { sample } from "lodash-es";
import { useRouter } from "@bprogress/next";
import toast from "react-hot-toast";
import { useDebouncedCallback } from "@knowt/syncing/utils/hooks/useDebouncedCallback";

const getStudentsPerSection = (classData?: Class): Record<string, boolean> => {
    if (!classData) return {};

    return classData.sections.reduce(
        (map, section) => {
            map[section.id] = classData.members.some(
                member => member.role === "STUDENT" && member.sections.includes(section.id)
            );
            return map;
        },
        {} as Record<string, boolean>
    );
};

const validateFields = (name: string, sections: ClassSection[]) => {
    if (!name.trim()) throw new Error("Please enter a class name");
    if (sections.some(section => !section.name.trim())) throw new Error("Please fill all section names");
};

const useClassSettings = (serverClass: Class | undefined) => {
    const { user } = useCurrentUser();
    const router = useRouter();

    const [classSettings, updateClassSettings] = useCombinedState<{
        name: string;
        description: string;
        grade: string | null;
        exam_v2: string | null;
        subject: string | null;
        sections: Array<ClassSection>;
        color: string;
    }>({
        name: serverClass?.name || "",
        description: serverClass?.description || "",
        grade: serverClass?.grade || null,
        exam_v2: serverClass?.exam_v2 || null,
        subject: serverClass?.subject || null,
        sections: serverClass?.sections || [
            {
                id: "1",
                name: "Section 1",
                color: SECTIONS_COLORS[0],
            },
        ],
        color: serverClass?.color || (sample(COVER_COLORS) as string),
    });

    const { name, sections } = classSettings;

    const handleDebounceUpdateClass = useDebouncedCallback((classId: string, updatedClassSettings: Partial<Class>) => {
        updateClass({ classId, ...updatedClassSettings }, user);
    }, 500);

    const handleCreateClass = async (denyRouting = false) => {
        try {
            validateFields(name, sections);
            const createdClass = await createClass({ ...classSettings, members: [] }, user);
            toast.success("Successfully created class!", { id: "CREATE_CLASS" });

            if (!denyRouting) {
                router.push(`/class/${createdClass?.classId}/dashboard`);
            }

            return createdClass.classId;
        } catch (err) {
            toast.error(err.message);
        }
    };

    const handleUpdateClass = async (classId: string) => {
        try {
            validateFields(name, sections);
            toast.loading("Updating class...", { id: "UPDATE_CLASS" });

            await updateClass({ classId, ...classSettings }, user);
            toast.success("Class updated successfully!", { id: "UPDATE_CLASS" });

            router.back();
        } catch (err) {
            toast.error(err.message, { id: "UPDATE_CLASS" });
        }
    };

    const handleUpdateAndSaveSettingsAutomatically = (classId, newSettings) => {
        updateClassSettings(newSettings);
        handleDebounceUpdateClass(classId, newSettings);
    };

    const updateNumberOfSections = (newCount: number, autoSave = false) => {
        const studentsPerSection = getStudentsPerSection(serverClass);

        if (Object.values(studentsPerSection).filter(Boolean).length > newCount) {
            return toast.error("Cannot reduce sections as some contain students");
        }

        const updatedSections = Array.from({ length: newCount }, (_, i) => ({
            id: `${i + 1}`,
            name: `Section ${i + 1}`,
            color: SECTIONS_COLORS[i % SECTIONS_COLORS.length],
        }));

        if (autoSave && serverClass?.classId) {
            handleUpdateAndSaveSettingsAutomatically(serverClass.classId, { sections: updatedSections });
        } else {
            updateClassSettings({ sections: updatedSections });
        }
    };

    const handleSectionNameChange = (index: number, newName: string) => {
        const updatedSections = [...sections];
        updatedSections[index].name = newName;
        updateClassSettings({ sections: updatedSections });
    };

    return {
        classSettings,
        updateClassSettings,
        handleSectionNameChange,
        updateNumberOfSections,
        handleCreateClass,
        handleUpdateClass,
        handleDebounceUpdateClass,
        handleUpdateAndSaveSettingsAutomatically,
    };
};

export default useClassSettings;
